// CheckboxGrid.jsx
import React from "react";

const CheckboxGrid = ({ selectedOptions, onChange, error }) => {
  const options = [
    { label: "Budgeting and Forecasting", value: "Budgeting & Forecasting" },
    { label: "Sales Planning", value: "Sales Planning" },
    { label: "Demand Planning", value: "Demand Planning" },
    { label: "Workforce Planning", value: "Workforce Planning" },
    {
      label: "Financial Reporting and Analysis",
      value: "Financial Reporting & Analysis",
    },
    {
      label: "Financial Consolidation and Close Processes",
      value: "Financial Consolidation & Close Processes",
    },
    {
      label: "Dashboards and Visualizations",
      value: "Dashboards & Visualizations",
    },
  ];

  const handleCheckboxChange = (value) => {
    if (selectedOptions.includes(value)) {
      onChange(selectedOptions.filter((item) => item !== value));
    } else {
      onChange([...selectedOptions, value]);
    }
  };

  return (
    <div className="p-4">
      <div
        className={`p-4 bg-white rounded-md ${
          error ? "border border-red-500" : "border border-gray-300"
        }`}
      >
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          {options.map((item, index) => (
            <label
              key={index}
              className="block space-y-1 text-sm text-gray-700 cursor-pointer"
            >
              <div className="flex items-start space-x-2">
                <input
                  type="checkbox"
                  checked={selectedOptions.includes(item.value)}
                  onChange={() => handleCheckboxChange(item.value)}
                  className="mt-1 form-checkbox text-blue-500 border-gray-300 rounded focus:ring focus:ring-blue-200"
                />
                <span>{item.label}</span>
              </div>
              {/* Optionally include helper text */}
              {/* <p className="text-xs text-gray-500">{item.helperText}</p> */}
            </label>
          ))}
        </div>
      </div>
      {error ? <p className="text-error-500 text-sm mt-2">{error}</p> : <></>}
    </div>
  );
};

export default CheckboxGrid;
