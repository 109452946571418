import React, { useState, useEffect } from 'react';
import MDEditor from '@uiw/react-md-editor';
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedRequirement } from '../../../../../redux/requirements/requirementsSlice';

const defaultMarkdown = `# Edit Requirements

## This is a markdown editor to update the requirements DB.

You can use quotes

And also show images, as long as they live at a reliable URL:

![Alt Text for Image](https://source.unsplash.com/random)

You can show tables if you need to

| Header | Header |
|--------|--------|
| Cell | Cell |
| Cell | Cell |
| Cell | Cell |

Or **any number** of *other formatting* options.`

export default function MDEditContainer() {
  const dispatch = useDispatch();
  const selectedRequirement = useSelector(state => state.requirements.selectedRequirement);
  const [value, setValue] = useState(selectedRequirement?.description || defaultMarkdown);


  const handleDescriptionChange = (newValue) => {
    setValue(newValue);
    // Update the Redux state
    dispatch(updateSelectedRequirement({ 
      id: selectedRequirement._id, 
      property: 'description', 
      value: newValue 
    }));
  };

  const [availableHeight, setAvailableHeight] = useState(window.innerHeight - 300);

  useEffect(() => {
    const handleResize = () => {
      setAvailableHeight(window.innerHeight - 300);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="h-full p-2">
      <MDEditor
        className='h-full'
        height={availableHeight}
        value={value}
        onChange={handleDescriptionChange}
      />
    </div>
  );
}
