// TagInput.jsx
import React, { useState } from "react";

const TagInput = ({ tags, setTags, error }) => {
  const [inputValue, setInputValue] = useState("");

  const handleRemoveTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  const handleAddTag = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      setTags([...tags, inputValue.trim()]);
      setInputValue("");
      e.preventDefault(); // Prevent form submission on Enter
    }
  };

  return (
    <div>
      <div
        className={`border ${
          error ? "border-red-500" : "border-gray-300"
        } bg-gray-50 rounded-md shadow-sm text-sm flex items-center flex-wrap p-1 gap-2 w-full focus-within:outline outline-blue-600`}
      >
        {tags.map((tag, index) => (
          <div
            key={index}
            className="flex items-center bg-gray-200 text-gray-700 px-2 py-1 rounded-full space-x-2"
          >
            <span className="text-xs">{tag}</span>
            <button
              type="button"
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
              onClick={() => handleRemoveTag(index)}
            >
              &times;
            </button>
          </div>
        ))}
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleAddTag}
          className="flex-grow p-1 bg-gray-50 text-gray-700 focus:outline-none text-sm ml-1"
          placeholder="Type and press 'return'"
        />
      </div>
      {error && <p className="text-error-500 text-sm mt-1">{error}</p>}
    </div>
  );
};

export default TagInput;
