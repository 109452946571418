import React, { Fragment, useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import MDEditor from "@uiw/react-md-editor";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { useSelector } from "react-redux";

const SlideOutDetail = ({ item, onClose, isOpen }) => {
  const [availableHeight, setAvailableHeight] = useState(
    window.innerHeight - 350
  );

  const selectedItem = useSelector(
    (state) => state.scoreVendors.requirementDetailView
  );

  useEffect(() => {
    const handleResize = () => {
      setAvailableHeight(window.innerHeight - 350);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          {/* Background overlay */}
          <TransitionChild
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-50"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-50"
            leaveTo="opacity-0"
          >
            <Dialog
              onClose={onClose}
              className="absolute inset-0 bg-gray-500/70 transition-opacity"
            />
          </TransitionChild>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16 ">
            <TransitionChild
              as={Fragment}
              enter="transform transition ease-in-out duration-300 sm:duration-300"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300 sm:duration-300"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              {selectedItem && (
                <div className="w-screen max-w-2xl overflow-hidden">
                  <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <DialogTitle className="text-lg font-medium text-gray-900">
                          {" "}
                          {item.title}{" "}
                        </DialogTitle>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                            onClick={onClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-12 relative flex-1 px-4 sm:px-6">
                      <div
                        onClick={onClose}
                        className="absolute right-6 top-0 bg-gray-50 hover:bg-gray-200 cursor-pointer p-1 rounded-lg"
                      >
                        <XIcon className="h-4 w-4" />
                      </div>
                      <h1 className="text-2xl font-bold mb-6 pr-8">
                        {selectedItem.name}
                      </h1>
                      <ul className="text-sm grid grid-cols-1 sm:grid-cols-2 gap-3 pb-6 bg-grayBlue-50 border border-gray-200 rounded-md p-3 mb-6">
                        <div>
                          <div className="text-sm font-semibold text-grayBlue-800 mb-1 ml-1">
                            Category:
                          </div>
                          <div className="p-1 rounded-lg px-2 border border-gray-300 bg-white">
                            {selectedItem.category}
                          </div>
                        </div>
                        <div>
                          <div className="text-sm font-semibold text-grayBlue-800 mb-1 ml-1">
                            Functional Area:
                          </div>
                          <div className="p-1 rounded-lg px-2 border border-gray-300 bg-white">
                            {selectedItem.functionalArea}
                          </div>
                        </div>
                        <div>
                          <div className="text-sm font-semibold text-grayBlue-800 mb-1 ml-1">
                            Applicable Industries:
                          </div>
                          <div className="p-1 rounded-lg px-2 border border-gray-300 bg-white">
                            {selectedItem.industry}
                          </div>
                        </div>
                        <div>
                          <div className="text-sm font-semibold text-grayBlue-800 mb-1 ml-1">
                            Applicable Company Sizes:
                          </div>
                          <div className="p-1 rounded-lg px-2 border border-gray-300 bg-white">
                            {selectedItem.companySize}
                          </div>
                        </div>
                      </ul>
                      <MarkdownPreview
                        className="h-full"
                        source={selectedItem.description}
                      />
                    </div>
                  </div>
                </div>
              )}
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SlideOutDetail;
