import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { toggleSlideout } from '../../../../redux/questions/questionsSlice'; 
import CreateCategoryForm from './CreateCategoryForm';

export default function CategorySlideout() {
  const dispatch = useDispatch();
  const slideoutOpen = useSelector(state => state.questions.slideoutOpen);

  const handleClose = () => {
    dispatch(toggleSlideout());
  };

  return (
    <Transition.Root show={slideoutOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <div className="fixed inset-0 font-sans" />
          {/* Overlay */}
          <div className="fixed inset-0 bg-black/30 z-0" onClick={handleClose} />


        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between py-6">
                        <Dialog.Title className="text-base leading-6 text-gray-900">
                          
                          <div className='text-2xl font-sans font-bold'>Add New Category</div>
                          <div className='text-md font-sans text-gray-600 '>Create and customize your category data to suit your specific needs.</div>
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0"
                            onClick={handleClose}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative h-full">
                      {/* Slideout Content */}
                      <CreateCategoryForm />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
