import React from "react";
import Spinner from "../../../../components/Spinner";

export default function AiLoading() {
  return (
    <div className="w-full flex flex-col items-center justify-center">
      <Spinner />
      <div className="text-sm font-gray-800 p-3">
        Generating Scorecard using Shortlist Match AI...
      </div>
    </div>
  );
}
