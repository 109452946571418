import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SignIn from "./pages/AdminPortal/SignIn/SignIn";
import Page404 from "./pages/Common/Page404/Page404";
import TermsAndConditions from "./pages/Common/TermsAndConditions/TermsAndConditions";
import ResetPassword from "./pages/AdminPortal/SignIn/components/ResetPassword";
import RequirementsDatabase from "./pages/AdminPortal/RequirementsDatabase/RequirementsDatabase";
import QuestionnaireEditor from "./pages/AdminPortal/QuestionnaireEditor/QuestionnaireEditor";
import Customers from "./pages/AdminPortal/Customers/Customers";
import CustomerLogin from "./pages/CustomerPortal/CustomerLogin/CustomerLogin";
import Questionnaire from "./pages/AdminPortal/Questionnaire/Questionnaire";
import VendorDatabase from "./pages/AdminPortal/VendorDatabase/VendorDatabase";
import QuickQuestionnaire from "./pages/Common/QuickQuestionnaire/QuickQuestionnaire";
import QuickResults from "./pages/Common/QuickResults/QuickResults";

import { ProtectedRoute } from "./components/ProtectedRoute";
import { CustomerPortalWrapper } from "./components/CustomerPortalWrapper/CustomerPortalWrapper";
import CustomerCreateDemoScorecard from "./pages/CustomerPortal/CustomerCreateDemoScorecard/CustomerCreateDemoScorecard";
import CustomerMyScorecards from "./pages/CustomerPortal/CustomerMyScorecards/CustomerMyScorecards";
import CustomerTeamResults from "./pages/CustomerPortal/CustomerTeamResults/CustomerTeamResults";
import CustomerCreateTeam from "./pages/CustomerPortal/CustomerCreateTeam/CustomerCreateTeam";

import ScoreVendors from "./pages/CustomerPortal/ScoreVendors/ScoreVendors";
import QQAnalytics from "./pages/AdminPortal/QQAnalytics/QQAnalytics";

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <Page404 />,
    element: <CustomerLogin />,
  },
  {
    path: "/admin-login",
    errorElement: <Page404 />,
    element: <SignIn />,
  },
  {
    path: "/customer-login",
    errorElement: <Page404 />,
    element: <CustomerLogin />,
  },
  {
    path: "/create-team",
    errorElement: <Page404 />,
    element: (
      <CustomerPortalWrapper>
        <CustomerCreateTeam />
      </CustomerPortalWrapper>
    ),
  },
  {
    path: "/view-team-results",
    errorElement: <Page404 />,
    element: (
      <CustomerPortalWrapper>
        <CustomerTeamResults />
      </CustomerPortalWrapper>
    ),
  },
  {
    path: "/my-shortlists",
    errorElement: <Page404 />,
    element: (
      <CustomerPortalWrapper>
        <CustomerMyScorecards />
      </CustomerPortalWrapper>
    ),
  },
  {
    path: "/create-demo-scorecard",
    errorElement: <Page404 />,
    element: (
      <CustomerPortalWrapper>
        <CustomerCreateDemoScorecard />
      </CustomerPortalWrapper>
    ),
  },
  {
    path: "/score-vendors",
    errorElement: <Page404 />,
    element: (
      <CustomerPortalWrapper>
        <ScoreVendors />
      </CustomerPortalWrapper>
    ),
  },
  {
    path: "/questionnaire",
    errorElement: <Page404 />,
    element: <Questionnaire />,
  },
  {
    path: "/reset-password",
    errorElement: <Page404 />,
    element: <ResetPassword />,
  },
  {
    path: "/requirements-database",
    element: (
      <ProtectedRoute>
        <RequirementsDatabase />
      </ProtectedRoute>
    ),
  },
  {
    path: "/quick-questionnaire",
    element: <QuickQuestionnaire />,
  },
  {
    path: "/quick-results",
    element: <QuickResults />,
  },
  {
    path: "/vendor-database",
    element: (
      <ProtectedRoute>
        <VendorDatabase />
      </ProtectedRoute>
    ),
  },
  {
    path: "/qq-analytics",
    element: (
      <ProtectedRoute>
        <QQAnalytics />
      </ProtectedRoute>
    ),
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
  },
  {
    path: "/questionnaire-editor",
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <QuestionnaireEditor />
          </ProtectedRoute>
        ),
      },
    ],
    errorElement: <Page404 />,
  },
  {
    path: "/customers",
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <Customers />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
