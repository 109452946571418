import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../AdminPortal/components/PageHeader/PageHeader.js";
import { useNavigate } from "react-router-dom";
import CustomerDetails from "./components/CustomerDetails/CustomerDetails";
import { fetchCustomers } from "../../../redux/customers/customersThunk";
import CustomerTable from "./components/CustomerTable/CustomerTable";
import Spinner from "../../../components/Spinner";

export default function VendorDatabase() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedCustomer = useSelector(
    (state) => state.customers.selectedCustomer
  );
  const customersLoadStatus = useSelector((state) => state.customers.status);

  useEffect(() => {
    if (customersLoadStatus === "init") {
      dispatch(fetchCustomers());
    }
  }, []);

  const actions = [];

  if (customersLoadStatus == "loading") {
    return (
      <div className="min-h-full flex flex-col items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return selectedCustomer ? (
    <div className="overflow-hidden">
      <CustomerDetails />
    </div>
  ) : (
    <div className="flex flex-col">
      <PageHeader pageName="Customer Management" actions={actions} />
      <CustomerTable />
    </div>
  );
}
