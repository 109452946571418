export const generateMagicLink = (vendors) => {
  // Extract the necessary fields from each vendor
  const strippedData = vendors.map(
    ({
      name,
      overallScore,
      budgetMatch,
      sourceSystemSupport,
      industryFocus,
    }) => ({
      name,
      overallScore,
      budgetMatch,
      sourceSystemSupport,
      industryFocus,
    })
  );

  // Convert the array to a JSON string
  const jsonString = JSON.stringify(vendors);

  // Encode the JSON string to Base64
  const base64String = btoa(jsonString);

  // Construct the URL with the encoded data as a query parameter
  const magicLink = `http://app.shortlistmatch.com/quick-results?mld=${encodeURIComponent(
    base64String
  )}`;

  return magicLink;
};

// const magicLink = generateMagicLink(vendors);
// console.log("Magic Link:", magicLink);
// const decodedVendors = decodeMagicLink(magicLink);
// console.log("Decoded Vendors:", decodedVendors);
