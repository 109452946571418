import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import React, { useState } from "react";

export default function Tooltip({ toolTipText }) {
  const [showTooltip, setShowTooltip] = useState(false);

  if (toolTipText == "") return <></>;

  return (
    <div className="relative">
      <div
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        className="cursor-pointer text-gray-600 hover:text-gray-700 hover:bg-gray-300 p-1 rounded-lg relative z-10"
      >
        <QuestionMarkCircleIcon className="h-4 w-4" />
        {showTooltip && (
          <div className="w-48 p-2 rounded-md absolute top-0 -left-24 overflow-y-auto bg-gray-100 text-xs shadow-md z-0">
            {toolTipText}
          </div>
        )}
      </div>
    </div>
  );
}
