import React from "react";
import { TrashIcon } from "@heroicons/react/outline";
import QuestionTypeDropdown from "./QuestionTypeDropdown";
import QuestionAnswersForm from "./QuestionAnswerForm";
import DragHandle from "../../components/DragHandle.js";
import {
  deleteQuestion,
  updateQuestionText,
  updateTooltipText,
} from "../../../../../redux/questions/questionsSlice"; // Adjust import path
import { useDispatch } from "react-redux";
import SubcategoryQuestionDropdown from "../SubcategoryQuestionDropdown";
import QuestionSearchInfoRow from "./QuestionSearchInfoRow";

const SubcategoryQuestionForm = ({ question, index }) => {
  const dispatch = useDispatch();

  console.log('SUBCATFORM')
  console.log(question)

  const handleDelete = () => {
    dispatch(deleteQuestion(question.id));
  };

  const handleTextChange = (e) => {
    dispatch(
      updateQuestionText({ questionId: question.id, text: e.target.value })
    );
  };
  
  const handleTooltipChange = (e) => {
    dispatch(
      updateTooltipText({ questionId: question.id, tooltip: e.target.value })
    );
  };

  return (
    <div className="flex flex-col items-start space-y-2 w-full pl-4 pb-8 border-b border-gray-300">
      <div className="text-gray-700 font-bold">Question {index + 1}</div>
      <div className="flex items-center space-x-2 w-full">
        <input
          type="text"
          value={question.text}
          placeholder="Type Question Here"
          onChange={handleTextChange}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600"
        />
        <QuestionTypeDropdown
          selectedValue={question.type}
          questionId={question.id}
        />
        <SubcategoryQuestionDropdown handleDelete={handleDelete} />
      </div>
      <div className="w-full flex flex-col space-y-2">
        {question.type === "One Line Text Entry" ||
        question.type === "Source Systems" ||
        question.type === "" ||
        question.type === "Large Text Field" ? null : (
          <QuestionAnswersForm question={question} />
        )}
      </div>
      <QuestionSearchInfoRow
        questionId={question.id}
        questionProperty={question.targetProperty}
        questionScoreMethod={question.scoreMethod}
        questionShowImportance={question.showImportance}
      />
      <div className="mt-2 w-full">
      <div className='text-xs font-semibold mb-0.5'>Tooltip</div>
       <input
          type="text"
          value={question.tooltip}
          placeholder="Type Tooltip Here (optional)"
          onChange={handleTooltipChange}
          className="text-xs block w-full border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600"
        />
        </div>
    </div>
  );
};

export default SubcategoryQuestionForm;
