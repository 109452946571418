import React, { useState, useEffect } from "react";
import TeamScores from "./components/TeamScores/TeamScores.js";
import SelectWinner from "./components/SelectWinner.js";
import CustomerBackToShortlists from "../components/CustomerBackToShortlists.js";
import { useDispatch, useSelector } from "react-redux";
import { getScorecardByQuestionnaireId } from "../../../redux/scorecards/scorecardsThunk.js";
import { initForm, setCategories } from "../../../redux/scoreVendors/scoreVendorsSlice.js";

export default function CustomerTeamResults() {
  const [activeTab, setActiveTab] = useState("TeamScores");
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.auth.user);
  const newScoreCardData = useSelector(
    (state) => state.scorecards.selectedScorecard?.scoreCardData
  );

  
  
  // UseEffect to load scorecard based on URL ID
  useEffect(() => {
    dispatch(initForm());
    const urlParams = new URLSearchParams(window.location.search);
    const qid = urlParams.get("qid");
    dispatch(getScorecardByQuestionnaireId({ id: qid }));
  }, [dispatch]);

  
  useEffect(() => {
    // Block if needed
    if (!newScoreCardData || !currentUser) return;

    const newScoreCardDataParsed = JSON.parse(newScoreCardData);

    // This extracts the category + item data from the scorecardData.
    function transformData(data) {
      // Group data by 'functionalArea' into an object map
      const groupedByCategory = data.reduce((acc, item) => {
        const key = item.functionalArea;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item.name);
        return acc;
      }, {});

      // Convert the grouped object map into the desired array format with 'items' key
      const transformedArray = [];
      Object.keys(groupedByCategory).forEach((category) => {
        const categoryObject = {
          category: category,
          items: groupedByCategory[category],
        };
        transformedArray.push(categoryObject);
      });

      return transformedArray;
    }

    const extractedCategoryData = transformData(newScoreCardDataParsed);

    // Set these locally, and dispatch an update to redux
    dispatch(setCategories(extractedCategoryData));
  }, [newScoreCardData]);

  

  return (
    <div className="bg-grayBlue-50 pb-48">
      <div className="pt-6 pl-6 bg-white border-b border-gray-200">
        <CustomerBackToShortlists />
        <div className="text-2xl font-bold text-gray-800">
          Team Review
        </div>
        <div className="flex justify-start w-1/2 py-3 overflow-auto">
        <button
          className={`flex-grow px-4 py-2 rounded-l-md ${
            activeTab === "TeamScores"
              ? "p-1.5 px-3 text-sm bg-white font-semibold shadow-sm border cursor-pointer"
              : "p-1.5 px-3 text-sm bg-gray-50 border border-gray-200 shadow-sm cursor-pointer"
          }`}
          onClick={() => setActiveTab("TeamScores")}
        >
          Team Scores
        </button>
        <button
          className={`flex-grow px-4 py-2 rounded-r-md ${
            activeTab === "SelectWinner"
              ? "p-1.5 px-3 text-sm bg-white font-semibold shadow-sm border cursor-pointer"
              : "p-1.5 px-3 text-sm bg-gray-50 border border-gray-200 shadow-sm cursor-pointer"
          }`}
          onClick={() => setActiveTab("SelectWinner")}
        >
          Select Winner
        </button>
      </div>
      </div>
      
      <div>
        {activeTab == "SelectWinner" && <SelectWinner />}
        {activeTab == "TeamScores" && <TeamScores />}
      </div>
    </div>
  );
}
