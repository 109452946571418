import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearAuthErrors, unlockClient } from "../../../../redux/auth/authSlice";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Spinner from "../../../../components/Spinner";
import { login } from "../../../../redux/auth/authThunk";

export default function PasswordProtect() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [resetPassword, setResetPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(clearAuthErrors());
    dispatch(login({password}))
  };


  useEffect(() => {
    if (!auth.passwordProtect) {
      navigate("/questionnaire-editor")
    }
  }, [auth]);

  
  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            {/* <img className="mx-auto h-6 w-auto" src={Logo} alt="Your Company" /> */}
            <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900">
              Welcome
            </h2>
            <p className="my-3 mb-6 text-center text-sm text-gray-600">
              Enter password to continue.
            </p>
          </div>
          <form
            className="space-y-3"
            onSubmit={(e) => handleSubmit(e).catch((e) => console.log(e))}
          >
            
            {!resetPassword && (
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    className="text-black block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
              </div>
            )}
          

            <div className="pt-3">
              <button
                type="button"
                onClick={(e) => handleSubmit(e)
                }
                className="flex w-full justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                {auth.status=="loading" ? <Spinner /> : "Sign In"}
              </button>
            </div>
          </form>
          <div className="p-3 pt-6 text-center text-sm">Go to <a className="font-semibold text-blue-700 hover:text-blue-500" href='/customer-login'>Customer Portal</a></div>

         
        </div>
      </div>
      <Toaster position="bottom-center" />
    </div>
  );
}
