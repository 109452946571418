import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import {
  toggleSubcategoryModal,
  updateCurrentSubcategory, // New action to update subcategory in currentSubcategories
} from "../../../../redux/questions/questionsSlice";
import SubcategoryQuestionList from "./SubcategoryQuestionList";

export default function SubcategoryModal() {
  const dispatch = useDispatch();
  const modalOpen = useSelector(
    (state) => state.questions.subcategoryModalOpen
  );
  const currentSubcategories = useSelector(
    (state) => state.questions.currentSubcategories
  );
  const selectedSubcategory = useSelector(
    (state) => state.questions.selectedSubcategory
  );
  const [headline, setHeadline] = useState("");
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (selectedSubcategory) {
      setHeadline(selectedSubcategory.headline || "");
    }
  }, [selectedSubcategory]);

  const handleHeadlineChange = (e) => {
    setHeadline(e.target.value);
  };

  const handleSave = () => {
    const updatedSubcategory = {
      ...selectedSubcategory,
      headline: headline,
    };
    dispatch(toggleSubcategoryModal());
    dispatch(updateCurrentSubcategory(updatedSubcategory));
  };

  const handleClose = () => {
    dispatch(toggleSubcategoryModal());
  };

  // Placeholder for handling question changes
  const handleQuestionChange = (updatedQuestions) => {
    setQuestions(updatedQuestions);
  };

  return (
    <Transition.Root show={modalOpen} as={Fragment} className="">
      <Dialog as="div" className="relative z-20" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity z-20 " />
        </Transition.Child>

        <div className="fixed inset-0 z-30 w-screen overflow-y-auto ">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 max-w-4xl mx-auto">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white border-gray-200 border px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6 w-full sm:m-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center w-full sm:mt-0 sm:text-left ">
                    <div className="border-b pb-3">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 "
                      >
                        <div className="font-bold">
                          Add Questions to Subcategory
                        </div>
                        <p className="text-sm font-normal text-gray-500">
                          The questions added here will be presented to your
                          customers in the{" "}
                          <span className="font-bold">
                            Customer Questionnaire
                          </span>
                          .
                        </p>
                      </Dialog.Title>
                    </div>
                    <div className="">
                      {/* Content here */}
                      <div className="p-4">
                        <div className="mb-4">
                          <label
                            htmlFor="subcategory"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Subcategory
                          </label>
                          <input
                            type="text"
                            id="subcategory"
                            name="subcategory"
                            value={selectedSubcategory?.title || ""}
                            className="mt-1 block w-full border border-gray-300 bg-gray-100 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600"
                            disabled
                          />
                        </div>

                        <div className="mb-4">
                          <label
                            htmlFor="headline"
                            className="block text-sm font-medium text-gray-700"
                          >
                            <div className="text-black text-lg">
                              Create a Headline
                            </div>
                            <div className="text-gray-700 font-normal text-xs pb-2">
                              The headline serves as the page title; create one
                              throughtfully for this subcategory.
                            </div>
                          </label>
                          <input
                            type="text"
                            id="headline"
                            name="headline"
                            placeholder="Type Headline Here"
                            value={headline || ""}
                            onChange={handleHeadlineChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600"
                          />
                        </div>

                        {/* Placeholder for questionEditor */}
                        <div className="mb-4">
                          <label className="block text-sm font-medium text-gray-700">
                            Question Editor
                          </label>
                          <div className="mt-1 block w-full bg-grayBlue-50 rounded-md shadow-sm  p-3">
                            {/* Implement your question editor here */}
                            <SubcategoryQuestionList />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={() => handleClose()}
                    className="px-4 py-2 text-sm border border-gray-300 bg-white font-semibold text-gray-700 hover:bg-gray-100 rounded-md"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleSave()}
                    className="px-4 py-2 text-sm border border-blue-600 bg-blue-600 font-semibold text-white hover:bg-blue-700 rounded-md"
                  >
                    Save
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
