import React from "react";
import Logo from "../../../assets/logo.png";
import EmailInput from "./components/EmailInput";
import SSOButton from "./components/SSOButton";
import Divider from "./components/Divider";

export default function CustomerLogin() {
  return (
    <div className="flex h-[80vh] flex-1 flex-col items-center justify-center px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto h-12 w-auto" src={Logo} alt="ShortlistMatch" />
        <h2 className="leading-15 font-sm mt-5 text-center text-display-sm font-bold font-semibold text-gray-900 ">
          Log in to your account
        </h2>
        <p className="mb-6 mt-1 text-center text-md text-gray-600">
          Welcome back! Please check your email for login details.
        </p>
        <EmailInput />
        {/* <Divider /> */}
        {/* <div className=" space-y-2">
          <SSOButton provider="Google" />
          <SSOButton provider="Microsoft" />
        </div> */}
      </div>
      {/* <p className="mt-6 text-center sm:mx-auto text-sm sm:w-full sm:max-w-sm">
        Don't have an account?{" "}
        <a href="/signup" className="text-blue-600 font-bold hover:text-blue-700">
          Sign up
        </a>
      </p> */}
    </div>
  );
}
