import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addCategory,
  updateCategory,
  toggleSlideout,
  setSelectedCategory,
  toggleSubcategoryModal,
  setSelectedSubcategory,
  setCurrentSubcategories,
  setCurrentQuestions, // Import this action
} from "../../../../redux/questions/questionsSlice.js";
import SubcategoryModal from "./SubcategoryModal.js";
import { v4 } from "uuid";

import PreviewIcon from "../../../../assets/PreviewIcon.js";
import SubCategoriesCreator from "./SubCategoriesCreator.js";
import { saveQuestionsToDb } from "../../../../redux/questions/questionsThunk.js";
import { useNavigate } from "react-router-dom";
import { initQuestionnaire } from "../../../../redux/questionnaire/questionnaireSlice.js";

const CreateCategoryForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedCategory = useSelector(
    (state) => state.questions.selectedCategory
  );
  const currentSubcategories = useSelector(
    (state) => state.questions.currentSubcategories
  );
  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    if (selectedCategory) {
      setCategoryName(selectedCategory.title);
      dispatch(setCurrentSubcategories(selectedCategory.subcategories || []));
    } else {
      dispatch(setCurrentSubcategories([]));
    }
  }, [selectedCategory, dispatch]);

  const handleCategoryNameChange = (event) => {
    setCategoryName(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const categoryData = {
      id: selectedCategory ? selectedCategory.id : v4(),
      title: categoryName,
      subcategories: currentSubcategories,
    };

    if (selectedCategory) {
      dispatch(updateCategory(categoryData));
    } else {
      dispatch(addCategory(categoryData));
    }

    dispatch(toggleSlideout());
    dispatch(setSelectedCategory(null));

    dispatch(saveQuestionsToDb())
  };

  const handleEditSubcategory = (subcategory) => {
    dispatch(setCurrentQuestions(subcategory.questions));
    dispatch(setSelectedSubcategory(subcategory));
    dispatch(toggleSubcategoryModal());
  };

  const addNewSubcategory = () => {
    const newSubcategory = {
      id: v4(),
      title: "",
      questions: [],
      headline: "",
    };
    dispatch(
      setCurrentSubcategories([...currentSubcategories, newSubcategory])
    );
  };

  const handleSubcategoryTitleChange = (index, newTitle) => {
    const updatedSubcategories = currentSubcategories.map(
      (subcategory, idx) => {
        if (idx === index) {
          return { ...subcategory, title: newTitle };
        }
        return subcategory;
      }
    );
    dispatch(setCurrentSubcategories(updatedSubcategories));
  };

  const handlePreviewQuestionnaire = () => {
    dispatch(saveQuestionsToDb())
    dispatch(initQuestionnaire())
    navigate("/questionnaire")
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="p-6 h-full border-t border-grayBlue-200"
    >
      <div className="mb-4">
        <label
          htmlFor="categoryName"
          className="block text-sm font-medium text-gray-700"
        >
          Category Name
        </label>
        <input
          type="text"
          id="categoryName"
          name="categoryName"
          value={categoryName}
          onChange={handleCategoryNameChange}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600"
        />
      </div>
      <div className='pb-24'>
      <SubCategoriesCreator
        subcategories={currentSubcategories}
        handleSubcategoryTitleChange={handleSubcategoryTitleChange}
        handleEditSubcategory={handleEditSubcategory}
        addNewSubcategory={addNewSubcategory}
      />
</div>
      <div className="fixed bottom-0 w-full right-0 flex justify-between items-center p-6 border-t border-grayBlue-200 bg-grayBlue-25">
        <div
          className="cursor-pointer flex items-center text-sm space-x-2 bg-blue-50 hover:bg-blue-100 p-3 rounded-md py-2 text-blue-600 font-bold"
          onClick={() => handlePreviewQuestionnaire()}
        >
          <span>Preview Questionnaire </span>
          <PreviewIcon />
        </div>
        <div className="fkex space-x-2">
          <button
            type="button"
            onClick={() => dispatch(toggleSlideout())}
            className="px-4 py-2 text-sm border border-gray-300 bg-white font-bold text-gray-700 hover:bg-gray-100 rounded-md"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-sm border border-blue-600 bg-blue-600 font-bold text-white hover:bg-blue-700 rounded-md"
          >
            Save
          </button>
        </div>
      </div>
      <SubcategoryModal />
    </form>
  );
};

export default CreateCategoryForm;
