import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeVendor } from "../../../../../redux/scoreVendors/scoreVendorsSlice";

const TabController = () => {
  const dispatch = useDispatch();
  const vendors = useSelector((state) => state.scoreVendors.vendors);
  const currentVendor = useSelector(
    (state) => state.scoreVendors.currentVendor
  );

  const handleTabClick = (vendor) => {
    dispatch(changeVendor(vendor));
  };

  return (
    <div className="p-6 pb-0">
      <div>
        <div className="text-xs font-semibold pb-1">Select Vendor:</div>
        <div className="flex justify-between bg-gray-50 w-1/4">
          {vendors.map((vendor) => (
            <button
              key={vendor}
              className={`px-6 py-2 ${
                vendor === currentVendor
                  ? "p-1.5 px-3 pb-3 text-sm cursor-pointer text-blue-700 border-b-2 border-blue-700 font-semibold transition-all"
                  : "p-1.5 px-3 pb-3 text-sm border-gray-200 cursor-pointer transition-all"
              }`}
              onClick={() => handleTabClick(vendor)}
            >
              {vendor}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TabController;
