import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../AdminPortal/components/PageHeader/PageHeader.js";
// import SubcategoryQuestionList from './components/SubcategoryQuestionList';
import RequirementsTable from "./components/RequirementsTable/RequirementsTable";
import { useNavigate } from "react-router-dom";
import RequirementsCreator from "./components/RequirementsCreator/RequirementsCreator";
import { initNewRequirement, toggleEditor } from "../../../redux/requirements/requirementsSlice";
import { fetchRequirements } from "../../../redux/requirements/requirementsThunk";
import Spinner from "../../../components/Spinner";

export default function RequirementsDatabase() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showEditor = useSelector(state => state.requirements.showEditor)
  
  const requirementsStatus = useSelector(state => state.requirements.status)

  
  useEffect(() => {
    if (requirementsStatus === "init") {
      dispatch(fetchRequirements());
    }
  }, []);
  
  

  const handleCreateRequirement = () => {
    dispatch(initNewRequirement());
    dispatch(toggleEditor())
  };

  const actions = [
    {
      text: "Create Requirements",
      func: handleCreateRequirement,
    },
  ];
  
if (requirementsStatus == "loading") {
  return (
    <div className='min-h-full flex flex-col items-center justify-center'>
      <Spinner />
    </div>
  );
}

  return showEditor ? (
    <div className="overflow-hidden">
      <RequirementsCreator />
    </div>
  ) : (
    <div className="flex flex-col">
      <PageHeader pageName="Requirements Database" actions={actions} />
      <RequirementsTable />
    </div>
  );
}
