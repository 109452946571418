// SingleQuestion.jsx
import React from "react";
import Tooltip from "./Tooltip";

export default function SingleQuestion({
  label,
  currentValue,
  toolTipText,
  onChange,
  error,
  placeholder,
}) {
  return (
    <div className="mb-6">
      <div className="flex items-center pb-1">
        <label className="block text-md pr-2">{label}</label>
        <Tooltip toolTipText={toolTipText} />
      </div>
      <input
        value={currentValue}
        onChange={onChange}
        placeholder={placeholder}
        type="text"
        className={`border ${
          error ? "border-red-500" : "border-gray-300"
        } bg-gray-50 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600 w-full`}
      />
      {error && <p className="text-error-500 text-sm mt-1">{error}</p>}
    </div>
  );
}
