// src/components/AnalyticsChart/AnalyticsChart.js

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { getAnalytics } from "../../../../redux/questionnaire/questionnaireThunk";

const AnalyticsChart = () => {
  const dispatch = useDispatch();

  // Local state for filters
  const [uniqueIPs, setUniqueIPs] = React.useState(false);
  const [startDate, setStartDate] = React.useState("2024-01-01");
  const [endDate, setEndDate] = React.useState("2024-12-31");

  // Selectors to access Redux state
  const analyticsData = useSelector((state) => state.questionnaire.analytics);
  const status = useSelector((state) => state.questionnaire.status);
  const error = useSelector((state) => state.questionnaire.error);

  // Handler to fetch analytics data
  const handleRefresh = () => {
    dispatch(getAnalytics({ startDate, endDate, uniqueIPs }));
  };

  // Optionally, fetch data on component mount
  // useEffect(() => {
  //   handleRefresh();
  // }, []);

  const processDataForChart = () => {
    if (!analyticsData || analyticsData.length === 0) return null;

    const labels = [];
    const qqOpenedData = [];
    const qqSubmittedData = [];
    const qrViewedData = [];

    const dataByDate = {};

    analyticsData.forEach((item) => {
      const date = item._id.date;
      const page = item._id.page;
      const count = item.count;

      if (!date || !page) {
        return; // Skip if essential data is missing
      }

      if (!dataByDate[date]) {
        dataByDate[date] = {
          qqOpened: 0,
          qqSubmitted: 0,
          qrViewed: 0,
        };
      }

      if (page === "/quick-questionnaire") {
        dataByDate[date].qqOpened += count;
      } else if (page === "/scoreQuickQuestionnaire") {
        // Corrected page name
        dataByDate[date].qqSubmitted += count;
      } else if (page === "/quick-results") {
        dataByDate[date].qrViewed += count;
      }
    });

    Object.keys(dataByDate)
      .sort()
      .forEach((date) => {
        labels.push(date);
        qqOpenedData.push(dataByDate[date].qqOpened);
        qqSubmittedData.push(dataByDate[date].qqSubmitted);
        qrViewedData.push(dataByDate[date].qrViewed);
      });

    return {
      labels,
      datasets: [
        {
          label: "Questionnaire Opened",
          data: qqOpenedData,
          backgroundColor: "rgba(75,192,192,0.6)",
        },
        {
          label: "Questionnaire Submitted",
          data: qqSubmittedData,
          backgroundColor: "rgba(153,102,255,0.6)",
        },
        {
          label: "Results Viewed",
          data: qrViewedData,
          backgroundColor: "rgba(255,159,64,0.6)",
        },
      ],
    };
  };

  const chartData = processDataForChart();

  return (
    <div className="p-6 rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-900">Analytics Chart</h2>
      <div className="flex flex-col md:flex-row md:items-center md:space-x-6 mb-8">
        {/* Start Date Filter */}
        <div className="mb-4 md:mb-0">
          <label className="block text-gray-900 font-semibold mb-2">
            Start Date:
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-gray-500"
            />
          </label>
        </div>
        {/* End Date Filter */}
        <div className="mb-4 md:mb-0">
          <label className="block text-gray-900 font-semibold mb-2">
            End Date:
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-gray-500"
            />
          </label>
        </div>
        {/* Unique IPs Filter */}
        <div className="mb-4 md:mb-0">
          <label className="inline-flex items-center text-gray-900 font-semibold">
            <input
              type="checkbox"
              checked={uniqueIPs}
              onChange={(e) => setUniqueIPs(e.target.checked)}
              className="form-checkbox h-5 w-5 text-gray-600"
            />
            <span className="ml-2">Unique IPs</span>
          </label>
        </div>
        {/* Refresh Button */}
        <div>
          <button
            onClick={handleRefresh}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Refresh
          </button>
        </div>
      </div>
      {/* Loading and Error States */}
      {status === "loading" && <p className="text-gray-900">Loading data...</p>}
      {status === "failed" && <p className="text-red-500">Error: {error}</p>}
      {/* Chart Rendering */}
      {chartData && chartData.labels.length > 0 ? (
        <div className="p-4 rounded-lg shadow-md">
          <Bar
            data={chartData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                  labels: {
                    color: "#000000",
                  },
                },
                title: {
                  display: true,
                  text: "Questionnaire Analytics",
                  color: "#000000",
                  font: {
                    size: 16,
                  },
                },
              },
              scales: {
                x: {
                  stacked: true,
                  ticks: {
                    color: "#000000",
                  },
                  grid: {
                    color: "#bee3f8",
                  },
                },
                y: {
                  stacked: true,
                  beginAtZero: true,
                  ticks: {
                    color: "#000000",
                  },
                  grid: {
                    color: "#bee3f8",
                  },
                },
              },
            }}
          />
        </div>
      ) : (
        status === "succeeded" && (
          <p className="text-gray-900">
            No data available for the selected filters.
          </p>
        )
      )}
    </div>
  );
};

export default AnalyticsChart;
