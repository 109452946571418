import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../AdminPortal/components/PageHeader/PageHeader.js";
// import SubcategoryQuestionList from './components/SubcategoryQuestionList';
import RequirementsTable from "./components/VendorTable/VendorTable";
import { useNavigate } from "react-router-dom";
import VendorCreator from "./components/VendorCreator/VendorCreator";
import { initNewRequirement, toggleEditor } from "../../../redux/requirements/requirementsSlice";
import { fetchRequirements } from "../../../redux/requirements/requirementsThunk";
import { fetchVendors } from "../../../redux/vendors/vendorsThunk";
import VendorTable from "./components/VendorTable/VendorTable";
import Spinner from "../../../components/Spinner";

export default function VendorDatabase() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showEditor = useSelector(state => state.vendors.showEditor)
  const vendorStatus = useSelector(state => state.vendors.status)

  useEffect(() => {
    if (vendorStatus === "init") {
      dispatch(fetchVendors())
    }
  }, [])
  
  

  const handleCreateVendor = () => {
    // dispatch(initNewRequirement());
    // dispatch(toggleEditor())
  };

  const actions = [
    {
      text: "Create Vendor",
      func: handleCreateVendor,
    },
  ];

  if (vendorStatus == "loading") {
    return (
      <div className='min-h-full flex flex-col items-center justify-center'>
        <Spinner />
      </div>
    );
  }
  return showEditor ? (
    <div className="overflow-hidden">
      <VendorCreator />
    </div>
  ) : (
    <div className="flex flex-col">
      <PageHeader pageName="Vendor Database" actions={actions} />
      <VendorTable />
    </div>
  );
}
