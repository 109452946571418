import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Fragment } from "react";
import SingleLineTextInput from "../../../components/SingleLineTextInput.js";
import Dropdown from "../../../../../components/Dropdown.js";
import { v4 as uuidv4 } from "uuid";
import { addCustomRequirementToScorecard } from "../../../../../redux/scoreVendors/scoreVendorsSlice.js";
import { useDispatch } from "react-redux";

const functionalAreaOptions = [
  "Administration",
  "Analysis",
  "Analytics",
  "Collaboration",
  "Conslidation",
  "Cost",
  "Data Management",
  "General",
  "Implementation",
  "Integration",
  "Planning",
  "Reporting",
];

const CustomRequirementModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  confirmText,
  cancelText,
}) => {
  const [requirementText, setRequirementText] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleSubmitCustomRequirement = () => {
    console.log("submitting custom req");
    // Handle Validation
    setError("");
    if (requirementText == "" || selectedCategory == "") {
      setError("Please fill all required Fields");
      return;
    }

    // Handle submit
    const newReq = {
      _id: uuidv4(),
      name: requirementText,
      functionalArea: selectedCategory,
      category: "Corporate Performance Management",
      industry: "All",
      companySize: "All",
      description: description,
    };
    console.log(newReq);
    dispatch(addCustomRequirementToScorecard(newReq));
    onConfirm();
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog
              onClose={onClose}
              className="fixed inset-0 bg-gray-500/80 transition-opacity"
            />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                {title && (
                  <DialogTitle
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {title}
                  </DialogTitle>
                )}
                <div className="py-4 space-y-3">
                  <SingleLineTextInput
                    label="Requirement Text (*required)"
                    placeholder="This text will appear on your scorecard"
                    inputVal={requirementText}
                    inputUpdateFunc={setRequirementText}
                  />
                  <div>
                    <div className="pb-1 text-md text-gray-500 font-semibold">
                      Functional Area (*required)
                    </div>
                    <Dropdown
                      maxHeight="max-h-24"
                      options={functionalAreaOptions}
                      onSelectOption={setSelectedCategory}
                      selectedValue={selectedCategory}
                    />
                  </div>
                  <div>
                    <div className="pb-1 text-md text-gray-500 font-semibold">
                      Description (optional)
                    </div>

                    <textarea
                      placeholder="Additional Info, Example Use Cases, Considerations, Questions to ask Vendor, etc..."
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      className="border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600 w-full"
                      rows="4"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full text-center text-sm text-error-500">
                {error}
              </div>
              <div className="mt-5 sm:mt-6 flex items-end justify-end ">
                <button
                  type="button"
                  className="min-w-32 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                  onClick={handleSubmitCustomRequirement}
                >
                  {confirmText || "Confirm"}
                </button>
                <button
                  type="button"
                  variant="neutral"
                  className="mt-3 min-w-32 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onClose}
                >
                  {cancelText || "Cancel"}
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CustomRequirementModal;
