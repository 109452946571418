import React from "react";

export default function QuickActionButton({ text, func }) {
  return (
    <button
      className="w-full bg-blue-700 hover:bg-blue-800 text-white text-sm p-2 rounded-md px-4"
      onClick={func}
    >
      {text}
    </button>
  );
}
