// File: AdminPortal/pages/QQAnalytics/QQAnalytics.js

import React from "react";
import AnalyticsChart from "./components/AnalyticsChart";
import PageHeader from "../components/PageHeader/PageHeader";

const QQAnalytics = () => {
  const actions = [];

  return (
    <div className="flex flex-col">
      <PageHeader pageName="Quick Questionnaire Analytics" actions={actions} />
      <AnalyticsChart />
    </div>
  );
};

export default QQAnalytics;
