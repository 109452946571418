import React, { useState, useEffect } from "react";
import Spinner from "../../../../components/Spinner.js";

// Array of messages to display
const messages = [
  "AI Software Matching can take up to 30 seconds...",
  "Analyzing Customer Inputs...",
  "Confirming industry support...",
  "Analyzing support for your CPM use cases...",
  "Matching your annual budget requirements...",
  "Comparing organization size with CPM vendor customer profiles...",
  "Cross-Referencing Existing Results...",
  "Finalizing AI comparison against ShortlistMatch CPM vendor archive...",
  "Almost finished!",
  "Finalizing AI comparison against ShortlistMatch CPM vendor archive...",
];

export default function FancyLoader() {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    let timeoutId;

    // Function to handle message transition
    const handleMessageChange = () => {
      // Start fade-out
      setIsVisible(false);

      // Wait for fade-out transition before changing message
      timeoutId = setTimeout(() => {
        setCurrentMessageIndex((prevIndex) => {
          // Check if it's the last message
          if (prevIndex < messages.length - 1) {
            return prevIndex + 1;
          } else {
            // Stay on the last message
            return prevIndex;
          }
        });

        // Start fade-in
        setIsVisible(true);

        // If not the last message, set the next timeout
        setCurrentMessageIndex((prevIndex) => {
          if (prevIndex < messages.length - 1) {
            const randomDuration = Math.random() * (4000 - 1500) + 1500;
            timeoutId = setTimeout(handleMessageChange, randomDuration);
          }
          return prevIndex;
        });
      }, 500); // Duration should match the CSS transition duration
    };

    // Initial timeout
    const initialDuration = Math.random() * (4000 - 1500) + 1500;
    timeoutId = setTimeout(handleMessageChange, initialDuration);

    // Cleanup on unmount
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="w-screen flex flex-col items-center justify-start mt-48 bg-white ">
      <Spinner />
      <div className="p-4 text-sm">ShortlistMatch Agents at work</div>
      <div
        className={`mt-4 max-w-md px-3 text-center text-lg duration-150 text-gray-700 transition-all duration-500 ${
          isVisible ? "opacity-100" : "opacity-0 translate-y-3"
        }`}
      >
        {messages[currentMessageIndex]}
      </div>
    </div>
  );
}
