// QuickQuestionnaire.jsx
import React, { useState, useEffect } from "react";
import Header from "../../AdminPortal/Questionnaire/components/Header";
import QuickActionButton from "./components/QuickActionButton";
import CheckboxGrid from "./components/CheckboxGrid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SingleQuestion from "./components/SingleQuestion";
import TagInput from "./components/TagInput";
import {
  sendPageUpdate,
  submitQuickQuestionnaire,
} from "../../../redux/questionnaire/questionnaireThunk";
import WebflowFooter from "../../../components/WebflowFooter";
import Tooltip from "./components/Tooltip"; // Ensure Tooltip is imported
import FancyLoader from "../QuickResults/components/FancyLoader";

export default function QuickQuestionnaire() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const status = useSelector((state) => state.questionnaire.status);

  // State for SingleQuestion components
  const [industry, setIndustry] = useState("");
  const [revenue, setRevenue] = useState("");
  const [employeeCount, setEmployeeCount] = useState("");
  const [budget, setBudget] = useState("");

  // State for TagInput
  const [accountingSystem, setAccountingSystem] = useState([]);

  // State for CheckboxGrid
  const [functionalAreas, setFunctionalAreas] = useState([]);

  // State for validation errors
  const [errors, setErrors] = useState({});

  // Handler for CheckboxGrid
  const handleCheckboxChange = (selectedOptions) => {
    setFunctionalAreas(selectedOptions);
  };

  // Validation Function
  const validateForm = () => {
    const newErrors = {};

    if (!industry.trim()) {
      newErrors.industry = "Industry is required.";
    }

    if (!revenue.trim()) {
      newErrors.revenue = "Revenue is required.";
    }

    if (!employeeCount.trim()) {
      newErrors.employeeCount = "Employee Count is required.";
    }

    if (!budget.trim()) {
      newErrors.budget = "Budget is required.";
    }

    if (accountingSystem.length === 0) {
      newErrors.accountingSystem =
        "At least one Accounting System is required.";
    }

    if (functionalAreas.length === 0) {
      newErrors.functionalAreas = "At least one Functional Area is required.";
    }

    setErrors(newErrors);

    // If no errors, return true
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveQuickQuestionnaire = async () => {
    if (!validateForm()) {
      // If validation fails, do not proceed
      return;
    }

    const formData = {
      industry,
      revenue,
      employeeCount,
      budget,
      accountingSystem,
      functionalAreas,
    };
    try {
      await dispatch(submitQuickQuestionnaire({ formData })).unwrap();
      navigate("/quick-results");
    } catch (error) {
      // Handle error (e.g., show a notification)
      console.error("Failed to submit questionnaire:", error);
      // Optionally, set an error state to display a message to the user
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "development") {
      console.log("not sending analytics in dev env.");
    } else {
      dispatch(sendPageUpdate({ page: "/quick-questionnaire" }));
    }
  }, []);

  if (status === "loading") {
    return <FancyLoader />;
  }

  return (
    <div>
      <div className="isolate overflow-x-hidden relative z-20 bg-white px-6 py-8 sm:py-8 lg:px-8">
        <Header />
      </div>
      <div className="bg-gray-50 max-w-full py-10 text-center">
        <h1 className="mx-auto max-w-2xl mb-4 text-center font-bold text-2xl">
          Instamatch Questionnaire
        </h1>
        <p className="text-gray-400 px-10">
          Fill out just a few fields below and get an instant shortlist of CPM
          software matches!
        </p>
      </div>
      <div className="bg-white px-6 lg:px-48 sm:mx-40">
        <div className="pt-10">
          <SingleQuestion
            label="Industry"
            toolTipText=""
            placeholder="What is your company's Industry?"
            currentValue={industry}
            onChange={(e) => setIndustry(e.target.value)}
            error={errors.industry}
          />
        </div>
        <SingleQuestion
          label="Revenue"
          toolTipText=""
          placeholder="What is your company's approximate annual Revenue?"
          currentValue={revenue}
          onChange={(e) => setRevenue(e.target.value)}
          error={errors.revenue}
        />
        <SingleQuestion
          label="Employee Count"
          toolTipText=""
          placeholder="What is your company's approximate Employee Count?"
          currentValue={employeeCount}
          onChange={(e) => setEmployeeCount(e.target.value)}
          error={errors.employeeCount}
        />
        <SingleQuestion
          label="Annual Budget"
          toolTipText=""
          placeholder="What is your approximate Budget?"
          currentValue={budget}
          onChange={(e) => setBudget(e.target.value)}
          error={errors.budget}
        />
        <div className="mb-6">
          <div className="flex items-center pt-1 pb-2">
            <label className="block text-md pr-2">
              Current Accounting Systems
            </label>
            <Tooltip toolTipText="What accounting systems does your company currently use? input as many as you would like." />
          </div>
          <TagInput
            tags={accountingSystem}
            setTags={setAccountingSystem}
            error={errors.accountingSystem}
          />
        </div>
        <div className="mb-4">
          <div className="block text-lg font-bold pr-2 pt-1">
            Your Requirements
          </div>
          <CheckboxGrid
            selectedOptions={functionalAreas}
            onChange={handleCheckboxChange}
            error={errors.functionalAreas}
          />
        </div>
        <div className="mb-10">
          <QuickActionButton
            text="Submit"
            func={handleSaveQuickQuestionnaire}
          />
        </div>
      </div>
      <WebflowFooter />
    </div>
  );
}
