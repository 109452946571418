import {
  ChartBarIcon,
  ClipboardIcon,
  ClipboardListIcon,
  CollectionIcon,
  DatabaseIcon,
  HomeIcon,
  LogoutIcon,
  PencilIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
  UserIcon,
  ViewListIcon,
  WifiIcon,
} from "@heroicons/react/outline";
import { NavLink, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/auth/authThunk";
import { MenuIcon } from "@heroicons/react/solid";
import { useRef, useState } from "react";
import { useOnClickOutside } from "../utils/customHooks";
import Logo from "../assets/logo.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Sidebar() {
  const currentUser = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setSidebarOpen(false));
  const dispatch = useDispatch();
  const navigation = [
    // {
    //   name: "Home",
    //   href: "/home",
    //   icon: HomeIcon,
    // },
    {
      name: "Questionnaire Editor",
      href: "/questionnaire-editor",
      icon: CollectionIcon,
    },
    {
      name: "Requirements Database",
      href: "/requirements-database",
      icon: DatabaseIcon,
    },
    {
      name: "Vendor Database",
      href: "/vendor-database",
      icon: ClipboardListIcon,
    },
    {
      name: "Customers",
      href: "/customers",
      icon: UserIcon,
    },
    {
      name: "QQ Analytics",
      href: "/qq-analytics",
      icon: ChartBarIcon,
    },
    // {
    //   name: "Picklist Populator",
    //   href: "/picklist-populator",
    //   icon: ClipboardListIcon,
    // },
  ];

  function handleNavigation(e) {
    if (e.target.tagName === "path" || e.target.tagName === "svg") {
      setSidebarOpen(!sidebarOpen);
    } else {
      navigate("/questionnaire-editor");
    }
  }

  return (
    <div ref={ref}>
      <div
        className={`fixed top-0 left-0 h-screen bg-white w-56 border-r border-grayBlue-200 z-10 transition-all duration-300 ease-in-out transform ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <nav className="flex-1 px-2 py-4 space-y-2 pt-20">
          {navigation.map((item) => (
            <NavLink
              key={item.name}
              onClick={() => setSidebarOpen(!sidebarOpen)}
              to={item.href}
              className={({ isActive }) =>
                classNames(
                  isActive
                    ? "bg-grayBlue-100 dark:text-white text-black"
                    : "text-black dark:text-white hover:bg-grayBlue-200 dark:bg-buttonDark-700 hover:text-black",
                  "group flex items-center px-2 py-2 w-full text-sm font-medium rounded-md transition-all duration-100"
                )
              }
            >
              <item.icon
                className="mr-3 flex-shrink-0 h-6 w-6"
                aria-hidden="true"
              />
              {item.name}
            </NavLink>
          ))}
          <NavLink
            to="/admin-login"
            onClick={() => {
              dispatch(logout());
            }}
            className="text-black dark:text-white hover:bg-grayBlue-400/50 dark:bg-buttonDark-700 hover:text-black group flex items-center px-2 py-2 w-full text-sm font-medium rounded-md"
          >
            <LogoutIcon
              className="mr-3 flex-shrink-0 h-6 w-6"
              aria-hidden="true"
            />
            Logout
          </NavLink>
        </nav>
      </div>
      <div className="w-0 md:w-64 h-screen bg-white dark:bg-grayBlue-600 fixed border-r-1 border-grayBlue-200 border z-10">
        <div className="flex-1 flex flex-col pb-2 ">
          <div onClick={(e) => handleNavigation(e)}>
            <div className="h-16 flex cursor-pointer fixed w-full bg-white border-b border-gray-200 transition-all text-white items-center justify-between p-3">
              <div className="md:hidden">
                <MenuIcon className="w-6 h-6 cursor-pointer text-black md:hidden" />
              </div>
              <img className="h-8" src={Logo} />
            </div>
          </div>
          <div className="pt-20 flex-1 hidden sm:flex flex flex-col overflow-y-auto ">
            <nav className="flex-1 px-2 py-4 space-y-2">
              {navigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className={({ isActive }) =>
                    classNames(
                      isActive
                        ? " bg-grayBlue-100 text-black"
                        : "text-black hover:bg-grayBlue-200 dark:bg-buttonDark-700 hover:text-black",
                      "group flex items-center px-2 py-2 w-full text-sm font-medium rounded-md transition-all duration-100"
                    )
                  }
                >
                  <item.icon
                    className="mr-3 flex-shrink-0 h-6 w-6"
                    aria-hidden="true"
                  />
                  {item.name}
                </NavLink>
              ))}
              <NavLink
                to="/admin-login"
                onClick={() => {
                  dispatch(logout());
                }}
                className="text-gray-400 dark:text-white hover:bg-grayBlue-200 dark:bg-buttonDark-700 hover:text-black group flex items-center px-2 py-2 w-full text-sm font-medium rounded-md"
              >
                <LogoutIcon
                  className="mr-3  flex-shrink-0 h-6 w-6"
                  aria-hidden="true"
                />
                Logout
              </NavLink>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
