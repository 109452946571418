import React, { useState } from "react";
import { sendQQResultsEmail } from "../../../../redux/questionnaire/questionnaireThunk";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../../components/Spinner";

const SaveResultsForm = () => {
  const dispatch = useDispatch();

  const magicLink = useSelector((state) => state.questionnaire.magicLink);
  const status = useSelector((state) => state.questionnaire.status);

  const [email, setEmail] = useState("");

  const handleSaveResults = () => {
    dispatch(sendQQResultsEmail({ email, url: magicLink }));
  };

  return (
    <div className="w-full flex flex-col sm:flex-row items-center lg:space-x-4 md:space-x-4 pt-4 px-16 lg:px-80 w-full">
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email"
        className="w-full sm:flex-grow sm:basis-3/4 border bg-white rounded px-4 py-2 mb-4 sm:mb-0 text-sm"
      />
      {status === "loading" ? (
        <Spinner />
      ) : (
        <button
          onClick={handleSaveResults}
          className="w-full sm:flex-grow sm:basis-1/4 bg-white hover:bg-blue-600 hover:text-white font-semibold border rounded px-4 py-2 text-sm"
        >
          Send Results
        </button>
      )}
    </div>
  );
};

export default SaveResultsForm;
