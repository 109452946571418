import React, {useEffect} from "react";
import Spinner from "../../../../../components/Spinner";
import {useDispatch} from 'react-redux'
import { submitQuestionnaire } from "../../../../../redux/questionnaire/questionnaireThunk";

export default function SubmitLoading() {
  // TODO: Dispatch Submit from this page
  // On return of submit logic, update the view to show "Success" Screen
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(submitQuestionnaire())
  }, [])
  

  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center">
      <Spinner />
      <span className="text-sm p-2">Submitting your information...</span>
    </div>
  );
}
