import React from "react";
import { useSelector, useDispatch } from "react-redux";
import EmptyTable from "./EmptyTable";
import { EyeIcon } from "@heroicons/react/outline";
import { deleteRequirement } from "../../../../../redux/requirements/requirementsThunk";
import Pagination from "./Pagination";
import { getCustomerDetailsById } from "../../../../../redux/customers/customersThunk";
import { toggleCustomerEditor } from "../../../../../redux/customers/customersSlice";
import CustomerStatusChip from "../../../components/CustomerStatusChip.js";


const CustomerTable = () => {
  const customers = useSelector(
    (state) => state.customers.allCustomers
  );
  const dispatch = useDispatch();

  const handleEditClick = (customer) => {
    dispatch(getCustomerDetailsById({id: customer._id}))
  };

  const handleDeleteClick = (requirementId, requirementTitle) => {
    const userInput = window.prompt(
      `Please type '${requirementTitle}' to confirm deletion:`
    );

    if (userInput === requirementTitle) {
      dispatch(deleteRequirement(requirementId));
    } else {
      // Do nothing
    }
  };

  return (
    <div className="p-4">
      <div className="flex w-full min-h-full items-center justify-start flex-col text-center font-sans text-sm shadow-sm text-left">
        <div className="overflow-x-auto min-h-full min-h-full min-w-full ">
          {customers.length === 0 ? (
            <EmptyTable />
          ) : (
            <table className="min-w-full bg-white">
              <thead className="bg-gray-200 w-full border-separate text-gray-500">
                <tr>
                  <th className="text-left py-3 px-4 text-start rounded-tl-lg border-grayBlue-200 font-semibold">
                    Name
                  </th>
                  <th className="text-left py-3 px-4 text-start border-l border-grayBlue-200 font-semibold">
                    Email
                  </th>
                  <th className="text-left py-3 px-4 text-start border-l border-grayBlue-200 font-semibold">
                    Company
                  </th>
                  <th className="text-left py-3 px-4 text-start border-l border-grayBlue-200 font-semibold">
                    Status
                  </th>
                  <th className="text-left py-3 px-4 text-start border-l border-grayBlue-200 font-semibold">
                    Last Modified
                  </th>
                  <th className="text-left py-3 px-4 text-start rounded-tr-lg border-l border-grayBlue-200 font-semibold">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {customers.map((customer, index) => (
                  <tr
                    key={index}
                    className="border-b"
                  >
                    <td className="py-3 px-4 text-left">
                      {customer.firstName} {customer.lastName} 
                    </td>
                    <td className="py-3 px-4 text-left">
                      {customer.email}
                    </td>
                    <td className="py-3 px-4 text-left">{customer.company}</td>
                    <td className="py-3 px-4 text-left">
                      <CustomerStatusChip status={customer.status} />
                      </td>
                    <td className="py-3 px-4 text-left">{ new Date(customer.updatedAt).toLocaleString()}</td>
                    <td className="py-3 px-4 text-left flex gap-1">
                      <button
                        className="bg-gray-200 hover:bg-gray-300 py-1 px-2 rounded-md"
                        onClick={() => handleEditClick(customer)}
                      >
                        <EyeIcon className='h-5 w-5'/>
                      </button>
                      
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Pagination />
    </div>
  );
};

export default CustomerTable;
