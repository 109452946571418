import React, { useState, useEffect } from "react";
import Dropdown from "../../../../components/Dropdown.js";
import SingleLineTextInput from "../../components/SingleLineTextInput.js";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectWinner,
} from "../../../../redux/scoreVendors/scoreVendorsThunk.js";
import { toast } from "react-hot-toast";
import { unwrapResult } from "@reduxjs/toolkit";

export default function SelectWinner() {
  const [formData, setFormData] = useState({
    winner: "",
    reason: "",
    licenseCost: "",
    setupCost: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFormUpdate = (target, value) => {
    if (target === "reason") {
      setFormData({ ...formData, reason: value });
    }
    if (target === "licenseCost") {
      setFormData({ ...formData, licenseCost: value });
    }
    if (target === "setupCost") {
      setFormData({ ...formData, setupCost: value });
    }
    if (target === "winner") {
      setFormData({ ...formData, winner: value });
    }
  };

  const handleSubmit = () => {
    console.log("submitted");
    console.log(formData);

    const body = formData;
    const urlParams = new URLSearchParams(window.location.search);
    const qid = urlParams.get("qid");

    dispatch(selectWinner({ qid, body }))
      .then(unwrapResult) // Assuming you're using something like Redux Toolkit's unwrapResult
      .then((result) => {
        // Navigate on success
        navigate("/my-shortlists");
        toast.success("Winner Selected!");
      })
      .catch((err) => {
        // Handle any errors or unsuccessful submission cases here
        console.error("Failed to submit scores: ", err);
        toast.error("Error Saving Vendor Scores");
      });
  };

  const matchDataByQuestionnaire = useSelector(
    (state) => state.scorecards.matchDataByQuestionnaire
  );

  const currentVendors = useSelector(state => state.questionnaire.matchReportScores.selectedVendors)


  return (
    <div className="p-4">
      <h2 className=" pl-2 text-xl font-bold">Select Winner</h2>
      <div className="p-2 space-y-3">
        <Dropdown
          options={currentVendors}
          onSelectOption={(value) => handleFormUpdate("winner", value)}
        />
        <div>
          <label className="block text-sm font-medium text-grayBlue-700 mb-2">
            Tell us why this vendor won your business:
          </label>
          <textarea
            placeholder="This Vendor won our business because..."
            onChange={(e) => handleFormUpdate("reason", e.target.value)}
            value={formData.reason}
            className="border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600 w-full"
            rows="4"
          />
          <div className="mt-3">
            <SingleLineTextInput
              label="What was the license cost for the first year?"
              inputUpdateFunc={(value) =>
                handleFormUpdate("licenseCost", value)
              }
            />
          </div>
          <div className="mt-3">
            <SingleLineTextInput
              label="What about Setup Cost"
              inputUpdateFunc={(value) => handleFormUpdate("setupCost", value)}
            />
          </div>

          <div className="mt-6">
            <button
              className="bg-blue-700 hover:bg-blue-800 text-white text-md font-semibold p-2 rounded-md px-8"
              onClick={handleSubmit}
            >
              Submit Selected Winner
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
