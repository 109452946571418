import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import AddOptionDropdown from "./AddOptionDropdown";
import TagsInput from "./TagsInput";
import { updateSelectedRequirement } from "../../../../../redux/requirements/requirementsSlice";

export default function Form() {
  const dispatch = useDispatch();
  const selectedRequirement = useSelector(state => state.requirements.selectedRequirement);

  const [name, setName] = useState("");
  const [tags, setTags] = useState([]);
  const [category, setCategory] = useState("");
  const [functionalArea, setFunctionalArea] = useState("");
  const [industry, setIndustry] = useState("");
  const [companySize, setCompanySize] = useState("");

  useEffect(() => {
    if (selectedRequirement) {
      console.log('SELECTED')
      console.log(selectedRequirement)
      setName(selectedRequirement.name || "");
      setTags(selectedRequirement.tags || []);
      setCategory(selectedRequirement.category || "");
      setFunctionalArea(selectedRequirement.functionalArea || "");
      setIndustry(selectedRequirement.industry || "");
      setCompanySize(selectedRequirement.companySize || "");
    }
  }, [selectedRequirement]);

  const handleUpdateField = (field, value) => {
    dispatch(updateSelectedRequirement({ 
      id: selectedRequirement._id, 
      property: field, 
      value: value 
    }));
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex">
        <div className="w-full p-3">
          <label className="block text-sm font-medium text-grayBlue-700 mb-2">
            Requirement Name
          </label>
          <input
            onChange={(e) => {
              setName(e.target.value);
              handleUpdateField('name', e.target.value);
            }}
            type="text"
            value={name}
            className="border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600 w-full"
          />
        </div>
        <div className="w-full p-3">
          <label className="block text-sm font-medium text-grayBlue-700 mb-2">
            Tags
          </label>
          <TagsInput tags={tags} setTags={(newTags) => {
            setTags(newTags);
            handleUpdateField('tags', newTags);
          }} />
        </div>
      </div>
      <div className="p-3 flex space-x-2">
        <AddOptionDropdown 
          options={["Category 1", "Category 2", "Category 3", "Category 4"]}
          placeholderValue="Select Category"
          selectedValue={category}
          onSelectOption={(newCategory) => {
            setCategory(newCategory);
            handleUpdateField('category', newCategory);
          }}
        />
        <AddOptionDropdown 
          options={["Functional Area 1", "Functional Area 2", "Functional Area 3", "Functional Area 4"]}
          placeholderValue="Select Functional Area"
          selectedValue={functionalArea}
          onSelectOption={(newFunctionalArea) => {
            setFunctionalArea(newFunctionalArea);
            handleUpdateField('functionalArea', newFunctionalArea);
          }}
        />
        <AddOptionDropdown 
          options={["Industry 1", "Industry 2", "Industry 3", "Industry 4"]}
          placeholderValue="Select Industry"
          selectedValue={industry}
          onSelectOption={(newIndustry) => {
            setIndustry(newIndustry);
            handleUpdateField('industry', newIndustry);
          }}
        />
        <AddOptionDropdown 
          options={["Company Size 1", "Company Size 2", "Company Size 3", "Company Size 4"]}
          placeholderValue="Select Company Size"
          selectedValue={companySize}
          onSelectOption={(newCompanySize) => {
            setCompanySize(newCompanySize);
            handleUpdateField('companySize', newCompanySize);
          }}
        />
      </div>
    </div>
  );
}
