import React from "react";
import {useDispatch} from 'react-redux'
import { initNewRequirement, toggleEditor } from "../../../../../redux/requirements/requirementsSlice";

export default function EmptyTable() {
  const dispatch = useDispatch();
  const handleCreateNewRequirement = () => {
    dispatch(initNewRequirement())
    dispatch(toggleEditor())
  }
  return (
    <div className="flex w-full min-h-full items-center justify-start flex-col text-center font-sans text-sm">
      <table className="w-full">
        <thead className="bg-gray-200 w-full border-separate">
          <tr>
            <th className="text-left py-3 px-4 text-start rounded-tl-lg border-grayBlue-200">
              Category Name
            </th>
            <th className="text-left py-3 px-4 text-start border-l border-grayBlue-200">
              Functional Area
            </th>
            <th className="text-left py-3 px-4 text-start border-l border-grayBlue-200">
              Requirement
            </th>
            <th className="text-left py-3 px-4 text-start rounded-tr-lg border-l border-grayBlue-200">
              Actions
            </th>
          </tr>
        </thead>
      </table>
      <div className="w-full flex-grow flex flex-col items-center justify-center pb-36 bg-white border border-grayBlue-200 rounded-b-lg">
        <h1 className=" text-3xl font-bold p-4 pt-36">
          No Requirements have been added.
        </h1>
        <p className="text-gray-600 text-md pb-6">
          Would you like to add one now?
        </p>
        <button
          onClick={()=>handleCreateNewRequirement()}
          className="w-48 bg-blue-50 hover:bg-blue-100 p-2 px-6 rounded-md"
        >
          <span className="text-blue-700 font-bold">Add Requirements</span>
        </button>
      </div>
    </div>
  );
}
