import React, { useState } from "react";

import Logo from "../assets/logo.png";
import { subscribeToMailchimp } from "../redux/questionnaire/questionnaireThunk";
import Spinner from "./Spinner";
import { useDispatch, useSelector } from "react-redux";

const WebflowFooter = () => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.questionnaire.status);

  const [email, setEmail] = useState("");

  const handleSubscribe = () => {
    dispatch(subscribeToMailchimp({ email }));
  };
  return (
    <footer className="border-t border-gray-200 bg-white py-8">
      <div className="container mx-auto px-6 lg:px-20 flex flex-wrap justify-between items-start">
        {/* Left Section */}
        <div className="w-full lg:w-1/3 mb-8 lg:mb-0">
          <div className="flex items-center mb-4">
            <img className="h-8" src={Logo} alt="ShortlistAdvice Logo" />
          </div>
          <p className="text-gray-700 text-sm mb-8">
            Corporate Performance Management Software Selection Simplified
          </p>
          <p className="text-sm font-bold mb-6">
            Receive updates from ShortlistMatch about new vendors, blog posts,
            application updates, and more.
          </p>
          <div className="flex flex-col items-center space-y-6">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email address"
              className="border border-gray-300 rounded-3xl px-4 py-2 text-sm w-full"
            />
            {status === "loading" ? (
              <Spinner />
            ) : (
              <button
                onClick={handleSubscribe}
                className="bg-blue-600 text-white px-6 py-2 rounded-3xl text-xs self-start"
              >
                Subscribe
              </button>
            )}
          </div>
        </div>

        {/* Right Section */}
        <div className="w-full lg:w-1/3">
          <h3 className="text-xl font-semibold text-blue-700 mb-4">Site Map</h3>
          <div className="grid grid-cols-2 gap-4">
            <ul className="text-sm text-gray-700">
              <li className="mb-3">
                <a
                  href="https://www.shortlistmatch.com/get-started"
                  className="hover:underline"
                >
                  Get Started
                </a>
              </li>
              <li className="mb-3">
                <a
                  href="https://www.shortlistmatch.com/services/why-shortlist"
                  className="hover:underline"
                >
                  Why Shortlist?
                </a>
              </li>
              <li className="mb-3">
                <a
                  href="https://www.shortlistmatch.com/services/shortlisting"
                  className="hover:underline"
                >
                  Vendor Shortlisting
                </a>
              </li>
              <li className="mb-3">
                <a
                  href="https://www.shortlistmatch.com/services/demonstration-scorecards"
                  className="hover:underline"
                >
                  Demonstration Scorecards
                </a>
              </li>
              <li className="mb-3">
                <a
                  href="https://www.shortlistmatch.com/services/proposal-reviews"
                  className="hover:underline"
                >
                  Proposal Review
                </a>
              </li>
              <li className="mb-3">
                <a
                  href="https://www.shortlistmatch.com/#"
                  className="hover:underline"
                >
                  Requirementpedia
                </a>
              </li>
              <li className="mb-3">
                <a
                  href="https://www.shortlistmatch.com/resources/glossary"
                  className="hover:underline"
                >
                  Glossary
                </a>
              </li>
            </ul>
            <ul className="text-sm text-gray-700">
              <li className="mb-3">
                <a
                  href="https://www.shortlistmatch.com/blog-pages/blog"
                  className="hover:underline"
                >
                  Blog
                </a>
              </li>
              <li className="mb-3">
                <a
                  href="https://www.shortlistmatch.com/resources/faqs"
                  className="hover:underline"
                >
                  FAQs
                </a>
              </li>
              <li className="mb-3">
                <a
                  href="https://shortlistmatch.com/company/for-software-vendors"
                  className="hover:underline"
                >
                  For Software Vendors
                </a>
              </li>
              <li className="mb-3">
                <a
                  href="https://www.shortlistmatch.com/company/about"
                  className="hover:underline"
                >
                  About Us
                </a>
              </li>
              <li className="mb-3">
                <a
                  href="https://www.shortlistmatch.com/company/contact"
                  className="hover:underline"
                >
                  Contact Us
                </a>
              </li>
              <li className="mb-3">
                <a
                  href="https://www.shortlistmatch.com/company/legal"
                  className="hover:underline"
                >
                  Legal
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="mt-4 pt-4">
        <div className="container mx-auto px-6 lg:px-20 border-t border-gray-300 pt-6 flex justify-between items-center">
          <p className="text-sm text-gray-600">© Shortlist Match LLC</p>
          <a
            href="https://www.linkedin.com/company/shortlistmatch"
            className="text-blue-600 hover:underline"
          >
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M16 8a6 6 0 0111 0v10a6 6 0 01-11 0V8zM6 6h4v12H6zM4 6h4v12H4zM3 6h4v12H3z"
              />
            </svg> */}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default WebflowFooter;
