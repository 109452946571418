import { createSlice } from "@reduxjs/toolkit";
import {
  getAdminQuestionnaire,
  submitQuestionnaire,
  getGptScoresForQuestionnaire,
  adminSendResultsReady,
  getQuestionnaire,
  setMatchReportSelectedImages,
  submitQuickQuestionnaire,
  getAnalytics,
  sendQQResultsEmail,
  subscribeToMailchimp,
} from "./questionnaireThunk";
import toast from "react-hot-toast";

// This is what manages the actual taking of the questionnaire, storing answers
// as well as managing what the next + previous questions are.

const questionnaireSlice = createSlice({
  name: "questionnaire",
  initialState: {
    analytics: [],
    magicLink: "",
    categories: [],
    currentForm: null,
    nextForm: null,
    prevForm: null,
    currentCategoryIndex: 0,
    currentSubcategoryIndex: 0,
    selectedCategories: ["Company Details"],
    answers: [],
    gptScores: {
      isInitialLoad: true,
      selectedVendors: {},
      questionnaireId: null,
      overallScores: {},
      categories: [],
      matchReportSelectedVendors: ["", "", ""],
      matchReportSelectedImages: ["", "", ""],
      matchReportContactInfo: [
        {
          contactName: "",
          contactEmail: "",
          contactPhone: "",
        },
        { contactName: "", contactEmail: "", contactPhone: "" },
        { contactName: "", contactEmail: "", contactPhone: "" },
      ],
    },
    matchReportScores: {
      questionnaireId: null,
      selectedVendors: ["", "", ""],
      contactInfo: [
        {
          vendorName: "",
          contactName: "",
          contactEmail: "",
          contactPhone: "",
        },
        { contactName: "", contactEmail: "", contactPhone: "" },
        { contactName: "", contactEmail: "", contactPhone: "" },
      ],
      overallScores: {},
      categories: [],
    },
    quickQuestionnaireResults: [],
  },
  reducers: {
    // Based on the user's selection in the initial part of the quiz, we set these
    // up to calculate next and prev question logic.
    initQuestionnaire: (state, { payload }) => {
      state.currentCategoryIndex = 0;
      state.currentSubcategoryIndex = 0;
    },
    setMagicLink: (state, { payload }) => {
      state.magicLink = payload;
    },
    overrideMatchReportScore: (state, { payload }) => {
      console.log("Redux :: overrideMatchReportScore");
      console.log(payload);

      const { newScore, targetCategory, targetRequirement, targetVendor } =
        payload;

      // Find the category
      const categoryIndex = state.matchReportScores.categories.findIndex(
        (category) => category.title === targetCategory
      );

      if (categoryIndex === -1) {
        console.error("Category not found:", targetCategory);
        return;
      }

      const category = state.matchReportScores.categories[categoryIndex];

      // Find the question within the category
      const questionIndex = category.questionScores.findIndex(
        (questionScore) => questionScore.question === targetRequirement
      );

      if (questionIndex === -1) {
        console.error("Question not found:", targetRequirement);
        return;
      }

      const questionScore = category.questionScores[questionIndex];

      // Update the score for the target vendor
      questionScore[targetVendor] = newScore;

      // Propagate the changes back to the state
      state.matchReportScores.categories[categoryIndex].questionScores[
        questionIndex
      ] = questionScore;
    },
    setMatchReportVendors: (state, { payload }) => {
      state.gptScores.matchReportSelectedVendors[payload.index] = payload.value;
    },

    setMatchReportScores: (state, { payload }) => {
      state.matchReportScores = payload;
    },
    setMatchReportContactInfo: (state, { payload }) => {
      state.gptScores.matchReportContactInfo[payload.index] = payload.value;
    },
    setIsInitialLoad: (state, { payload }) => {
      state.gptScores.isInitialLoad = payload.isInitialLoad;
    },
    setSelectedCategories: (state, { payload }) => {
      state.categories = payload.categories;
    },
    setSelectedVendors: (state, { payload }) => {
      state.gptScores.selectedVendors = payload.selectedVendors;
    },
    setQuestionUserResponse: (state, { payload }) => {
      const { questionId, userResponse } = payload;
      const { categories, currentCategoryIndex, currentSubcategoryIndex } =
        state;

      if (currentCategoryIndex == 0 && currentSubcategoryIndex == 1) {
        state.selectedCategories = [
          ...userResponse,
          "Contact",
          "Company Details",
          "Your Tech Stack",
        ];
      }

      // Assuming that each subcategory has a 'questions' array
      let questions =
        categories[currentCategoryIndex].subcategories[currentSubcategoryIndex]
          .questions;

      // Find the question by ID and update its user response
      const questionIndex = questions.findIndex((q) => q.id === questionId);
      if (questionIndex !== -1) {
        questions[questionIndex].userResponse = userResponse;
      }
    },

    setQuestionUserImportanceRating: (state, { payload }) => {
      const { questionId, userImportanceRating } = payload;
      const { categories, currentCategoryIndex, currentSubcategoryIndex } =
        state;

      // Assuming that each subcategory has a 'questions' array
      let questions =
        categories[currentCategoryIndex].subcategories[currentSubcategoryIndex]
          .questions;

      // Find the question by ID and update its user response
      const questionIndex = questions.findIndex((q) => q.id === questionId);
      if (questionIndex !== -1) {
        questions[questionIndex].userImportanceRating = userImportanceRating;
      }
    },
    handleNextButton: (state, { payload }) => {
      const {
        currentCategoryIndex,
        currentSubcategoryIndex,
        categories,
        selectedCategories,
      } = state;

      // Function to find the next valid category index
      const findNextValidCategoryIndex = (currentIndex) => {
        let nextIndex = currentIndex + 1;
        while (nextIndex < categories.length) {
          if (selectedCategories.includes(categories[nextIndex].title)) {
            return nextIndex;
          }
          nextIndex++;
        }
        return null; // Indicates no valid next category found
      };

      if (
        currentSubcategoryIndex <
        categories[currentCategoryIndex].subcategories.length - 1
      ) {
        return {
          ...state,
          currentSubcategoryIndex: currentSubcategoryIndex + 1,
        };
      } else {
        const nextCategoryIndex =
          findNextValidCategoryIndex(currentCategoryIndex);
        if (nextCategoryIndex !== null) {
          return {
            ...state,
            currentCategoryIndex: nextCategoryIndex,
            currentSubcategoryIndex: 0,
          };
        } else {
          // End of categories, handle accordingly
        }
      }
    },
    handlePrevButton: (state, { payload }) => {
      const {
        currentCategoryIndex,
        currentSubcategoryIndex,
        categories,
        selectedCategories,
      } = state;

      // Function to find the previous valid category index
      const findPrevValidCategoryIndex = (currentIndex) => {
        let prevIndex = currentIndex - 1;
        while (prevIndex >= 0) {
          if (selectedCategories.includes(categories[prevIndex].title)) {
            return prevIndex;
          }
          prevIndex--;
        }
        return null; // Indicates no valid previous category found
      };

      if (currentSubcategoryIndex > 0) {
        return {
          ...state,
          currentSubcategoryIndex: currentSubcategoryIndex - 1,
        };
      } else if (currentCategoryIndex > 0) {
        const prevCategoryIndex =
          findPrevValidCategoryIndex(currentCategoryIndex);
        if (prevCategoryIndex !== null) {
          const prevSubcategoryIndex =
            categories[prevCategoryIndex].subcategories.length - 1;
          return {
            ...state,
            currentCategoryIndex: prevCategoryIndex,
            currentSubcategoryIndex: prevSubcategoryIndex,
          };
        } else {
          // Beginning of categories, handle accordingly
        }
      }
    },
  },
  extraReducers: {
    // --------
    // getAdminQuestionnaire
    // --------
    [getAdminQuestionnaire.pending]: (state) => {
      state.status = "loading";
      state.categories = null;
      state.error = "";
    },
    [getAdminQuestionnaire.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.categories = payload;
    },
    [getAdminQuestionnaire.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.categories = null;
      state.error = payload;
      toast.error(payload);
    },

    // --------
    // submitQuestionnaire
    // --------
    [submitQuestionnaire.pending]: (state) => {
      state.status = "submitting";
      state.error = "";
    },
    [submitQuestionnaire.fulfilled]: (state, { payload }) => {
      state.status = "success";
    },
    [submitQuestionnaire.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
      toast.error(payload);
    },

    // --------
    // getGptScoresForQuestionnaire
    // --------
    [getGptScoresForQuestionnaire.pending]: (state) => {
      state.status = "loading";
      state.error = "";
      state.gptScores.questionnaireId = null;
      state.gptScores.overallScores = {};
      state.gptScores.categories = [];
    },
    [getGptScoresForQuestionnaire.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.gptScores.questionnaireId = payload.questionnaireId;
      state.gptScores.overallScores = payload.scores.overallScores;
      state.gptScores.categories = payload.scores.categories;
    },
    [getGptScoresForQuestionnaire.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
      toast.error(payload);
    },

    // --------
    // adminSendResultsReady
    // --------
    [adminSendResultsReady.pending]: (state) => {
      state.status = "loading";
      state.error = "";
    },
    [adminSendResultsReady.fulfilled]: (state, { payload }) => {
      state.status = "success";
      toast.success("Sent Customer Email!");
    },
    [adminSendResultsReady.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
      toast.error(payload);
    },
    // --------
    // getQuestionnaire
    // --------
    [getQuestionnaire.pending]: (state) => {
      state.status = "loading";
      state.categories = null;
      state.error = "";
    },
    [getQuestionnaire.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.categories = payload;
    },
    [getQuestionnaire.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.categories = null;
      state.error = payload;
      toast.error(payload);
    },
    // --------
    // setMatchReportSelectedImages
    // --------
    [setMatchReportSelectedImages.pending]: (state) => {
      state.status = "loading";
    },
    [setMatchReportSelectedImages.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.gptScores.matchReportSelectedImages = payload;
    },
    [setMatchReportSelectedImages.rejected]: (state, { payload }) => {
      state.status = "failed";
    },
    // --------
    // submitQuickQuestionnaire
    // --------
    [submitQuickQuestionnaire.pending]: (state) => {
      state.status = "loading";
    },
    [submitQuickQuestionnaire.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.quickQuestionnaireResults = payload;
    },
    [submitQuickQuestionnaire.rejected]: (state, { payload }) => {
      state.status = "failed";
    },
    // --------
    // getAnalytics
    // --------
    [getAnalytics.pending]: (state) => {
      state.status = "loading";
    },
    [getAnalytics.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.analytics = payload;
    },
    [getAnalytics.rejected]: (state, { payload }) => {
      state.status = "failed";
    },
    // --------
    // sendQQResultsEmail
    // --------
    [sendQQResultsEmail.pending]: (state) => {
      state.status = "loading";
    },
    [sendQQResultsEmail.fulfilled]: (state, { payload }) => {
      state.status = "success";
      toast.success("Results sent to your inbox!");
    },
    [sendQQResultsEmail.rejected]: (state, { payload }) => {
      state.status = "failed";
      toast.error("Error sending results.");
    },
    // --------
    // subscribeToMailchimp
    // --------
    [subscribeToMailchimp.pending]: (state) => {
      state.status = "loading";
    },
    [subscribeToMailchimp.fulfilled]: (state, { payload }) => {
      state.status = "success";
      toast.success("Thanks for Subscribing!");
    },
    [subscribeToMailchimp.rejected]: (state, { payload }) => {
      state.status = "failed";
      toast.error("Already Subscribed.");
    },
  },
});

export const {
  overrideMatchReportScore,
  setMatchReportScores,
  setSelectedCategories,
  setSelectedVendors,
  setIsInitialLoad,
  updateCategory,
  addCategory,
  handleNextButton,
  handlePrevButton,
  initQuestionnaire,
  setQuestionUserResponse,
  setQuestionUserImportanceRating,
  setMatchReportVendors,
  setMatchReportContactInfo,
  setMagicLink,
} = questionnaireSlice.actions;
export default questionnaireSlice.reducer;
