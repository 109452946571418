import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ShortlistMatchCard from "../../CustomerMyScorecards/components/ShortlistMatchCard/ShortlistMatchCard.js";
import Timeline from "./ShortlistCard/Timeline.js";
import { setMatchDataForQuestionnaire } from "../../../../redux/scorecards/scorecardsSlice.js";
import WinnerDisplay from "./ShortlistCard/WinnerDisplay.js";
import DashboardMembersList from "./DashboardMembersList/DashboardMembersList.js";
import CardHeader from "./CardHeader.js";
import DashboardScorecard from "./DashboardScorcard.js";

const CustomerDashboard = ({ questionnaire }) => {
  const dispatch = useDispatch();
  const scorecards = useSelector((state) => state.scorecards.scorecards);
  const [currentScorecard, setCurrentScorecard] = useState(null);

  // Extract questionnaire Data
  const questionnaireScores = JSON.parse(questionnaire.matchReportItems);
  const createdAt = new Date(questionnaire.createdAt);
  const [categories, setCategories] = useState([]);
  const [matchReportItems, setMatchReportItems] = useState(
    questionnaire.matchReportItems
      ? JSON.parse(questionnaire.matchReportItems)
      : []
  );

  useEffect(() => {
    let displayCategories = [];

    if (!questionnaireScores || !questionnaireScores.categories) return;

    for (let i = 0; i < questionnaireScores.categories.length; i++) {
      if (questionnaireScores.categories[i].questionScores?.length > 0) {
        displayCategories.push(questionnaireScores.categories[i].title);
      }
    }

    if (matchReportItems && matchReportItems.length) {
      // Extract vendor names from matchReportItems
      const vendors = matchReportItems.map((item) => item.vendor || "");
      // Update the state with the new vendor names
      setCategories(displayCategories);
      dispatch(
        setMatchDataForQuestionnaire({
          questionnaireId: questionnaire._id,
          vendors: vendors,
        })
      );
    }
  }, [questionnaireScores]);

  useEffect(() => {
    if (scorecards?.length > 0) {
      // Loop through scorecards and find the one that matches the current questionnaire._id
      const matchingScorecard = scorecards.find(
        (scorecard) => scorecard.questionnaireId === questionnaire._id
      );

      // If a matching scorecard is found, set it as the current scorecard
      if (matchingScorecard) {
        setCurrentScorecard(matchingScorecard);
      } else {
        // In the case we don't find a match, it is possible that it is because we have a different scorecard
        // structure which is returned from the API. The long term fix for this is to ensure the API is always properly
        // aligned. However, as a hotfix, we are going to parse it in a way which matches the possible, alernative data structure.
        const altMatchingScorecard = scorecards.find(
          (scorecard) => scorecard.questionnaireId._id === questionnaire._id
        );

        // Optionally handle the case where no matching scorecard is found
        // For example, you could set currentScorecard to null or to an initial state
        if (altMatchingScorecard) {
          setCurrentScorecard(altMatchingScorecard);
        } else {
          setCurrentScorecard(null);
        }
      }
    }
  }, [scorecards, questionnaire]); // Make sure to include questionnaire in the dependency array

  return (
    <div className="border flex flex-col bg-gray-50 border-gray-200 shadow-sm hover:shadow-lg transition-all duration-300 p-2 md:p-6 relative ">
      <div className="flex flex-col ">
        <div className="flex flex-col">
          {/* Header */}
          <div>
            <CardHeader createdAt={createdAt} categories={categories} />
            {currentScorecard?.selectedWinner && (
              <WinnerDisplay winner={currentScorecard?.selectedWinner} />
            )}
          </div>
          {/* Main Content */}
          <div className="flex flex-col gap-2 p-2 md:gap-6 md:flex-row md:p-4">
            <div className="space-y-6 w-full md:w-1/3">
              <DashboardScorecard
                questionnaireId={questionnaire._id}
                scorecard={currentScorecard?.questionnaireId}
              />
              <DashboardMembersList questionnaireId={questionnaire._id} />
            </div>
            <div className="flex-1">
              <ShortlistMatchCard scores={questionnaireScores} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDashboard;
