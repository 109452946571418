import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShortlistCardListView from "./components/ShortlistCardListView";
import RequirementsCreator from "./components/RequirementsCreator/RequirementsCreator";
import { getMyCustomerDetails } from "../../../redux/customerAuth/customerAuthThunk";
import { setScorecards } from "../../../redux/scorecards/scorecardsSlice";
import { setMatchReportScores } from "../../../redux/questionnaire/questionnaireSlice";
import Spinner from "../../../components/Spinner";

export default function CustomerMyScorecards() {
  const dispatch = useDispatch();

  const showEditor = useSelector((state) => state.requirements.showEditor);
  const customerScorecards = useSelector(
    (state) => state.customerAuth.scorecards
  );
  const customer = useSelector((state) => state.customerAuth.customer);
  const status = useSelector((state) => state.customerAuth.status);

  useEffect(() => {
    if (customer?.completedQuestionnaires[0]?.matchReportItems) {
      dispatch(
        setMatchReportScores(
          JSON.parse(customer.completedQuestionnaires[0].matchReportItems)
        )
      );
    }
  }, [customer]);

  useEffect(() => {
    dispatch(getMyCustomerDetails());
  }, []);

  useEffect(() => {
    if (customerScorecards) {
      dispatch(setScorecards([...customerScorecards]));
    }
  }, [customerScorecards]);

  if (status === "loading")
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <Spinner />
        <div className="text-sm mt-6">Loading your Match Reports...</div>
      </div>
    );

  return showEditor ? (
    <div className="overflow-hidden">
      <RequirementsCreator />
    </div>
  ) : (
    <div className="flex flex-col">
      <ShortlistCardListView />
    </div>
  );
}
