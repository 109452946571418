import React from 'react'
import { useDispatch } from "react-redux";
import { setQuestionUserImportanceRating } from "../../../../../../redux/questionnaire/questionnaireSlice";

export default function RatingInputCell({questionId, text, isSelected}) {
  const dispatch = useDispatch();

  const handleUserResponse = (value) => {
    dispatch(
      setQuestionUserImportanceRating({
        questionId: questionId,
        userImportanceRating: Number(value),
      })
    );
  };

    if (isSelected) {
        return <button 
        onClick={() => handleUserResponse(text)}
        
        className="h-10 bg-blue-50 hover:bg-gray-100 hover:shadow-sm text-md flex-1 ring-2 ring-inset ring-blue-300 rounded-md font-semibold">{text}</button>
    }

  return (
    <button
    onClick={() => handleUserResponse(text)}
     className="h-10 hover:bg-gray-100 hover:shadow-sm text-md flex-1 ring-1 ring-inset ring-gray-300 rounded-md font-semibold">{text}</button>
  )
}