import { useState, useEffect } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import CategorySelect from "./components/defaultForms/CategorySelect";
import QuestionStep from "./components/QuestionStep/QuestionStep";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminQuestionnaire,
  getQuestionnaire,
} from "../../../redux/questionnaire/questionnaireThunk";
import {
  handleNextButton,
  handlePrevButton,
} from "../../../redux/questionnaire/questionnaireSlice";
import SubmittedForm from "./components/SubmittedForm/SubmittedForm";
import MultiSelectableSearchInput from "./components/inputs/SourceSystemsInput/MultiSelectableSearchInput";

import { systems } from "./components/inputs/SourceSystemsInput/systems";
import StartQuestionnaire from "./components/StartQuestionnaire/StartQuestionnaire";
import toast from "react-hot-toast";
import Spinner from "../../../components/Spinner";

export default function Questionnaire() {
  const dispatch = useDispatch();
  const currentQuestion = useSelector(
    (state) => state.questionnaire.currentQuestion
  );

  const questionnaireCategories = useSelector(
    (state) => state.questionnaire.categories
  );

  const status = useSelector((state) => state.questionnaire.status);

  const [animation, setAnimation] = useState("slideInRight");

  useEffect(() => {
    dispatch(getQuestionnaire());
  }, []);

  const handleNext = () => {
    setAnimation("slideOutLeft");
    setTimeout(() => {
      setAnimation("slideInRight");
      dispatch(handleNextButton());
    }, 300); // Delay should match your animation duration
  };

  const handleBack = () => {
    setAnimation("slideOutRight");
    setTimeout(() => {
      setAnimation("slideInLeft");
      dispatch(handlePrevButton());
    }, 300);
  };

  const handleSubmit = () => {
    console.log("SUBMITTING...");

    const lastCategory =
      questionnaireCategories[questionnaireCategories.length - 1];
    const questions = lastCategory.subcategories[0].questions;
    if (
      questions.length >= 3 &&
      questions[0].userResponse &&
      questions[1].userResponse &&
      questions[2].userResponse
    ) {
      // All required fields are filled
      console.log("All required questions are filled.");
      setSubmitted(true); // Uncomment this if you manage a submitted state
    } else {
      // Not all required fields are filled
      toast.error("First Name, Last Name, and Email are required");
      console.log("Required fields are missing.");
    }
  };

  const [submitted, setSubmitted] = useState(false);
  const [questionnaireHasStarted, setQuestionnaireHasStarted] = useState(false);

  if (status === "loading" && questionnaireHasStarted == true) {
    return (
      <div className="w-full h-screen flex flex-col items-center justify-center">
        <Spinner />
        <div className="text-sm mt-6">Loading Questionnaire...</div>
      </div>
    );
  }

  return !questionnaireHasStarted ? (
    <StartQuestionnaire setHasStarted={setQuestionnaireHasStarted} />
  ) : (
    <div className="isolate overflow-x-hidden bg-white px-6 py-24 sm:py-24 lg:px-8">
      {!submitted && <Header />}
      {!submitted && (
        <QuestionStep stepId={currentQuestion} animation={animation} />
      )}
      {submitted && <SubmittedForm />}
      {!submitted && (
        <Footer
          handleNext={handleNext}
          handleBack={handleBack}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
}
