import React from "react";
import { useSelector } from "react-redux";

const YourMatchReport = ({ vendors }) => {
  return (
    <div className="w-full bg-white rounded-lg p-4 border border-gray-200">
      {/* Header */}
      <h2 className="text-lg lg:text-xl font-bold lg:text-left text-center text-gray-800 mb-10 lg:mb-4 mt-4 lg:mt-4">
        Your Match Report
      </h2>

      {/* Overall Score */}
      <div className="flex flex-col lg:flex-row lg:items-end mb-6">
        <p className="text-xl lg:w-60 font-semibold lg:font-normal pb-6 lg:pb-0 text-center text-gray-700">
          Overall Score
        </p>
        <div className="grid grid-col-3 grid-flow-col text-sm w-full text-gray-700">
          {vendors.map((item, index) => (
            <div key={index} className="text-center px-2">
              <div className="container mb-4 h-20 md:h-32 lg:h-32 w-20 md:w-32 lg:w-32 mx-auto flex items-center justify-center">
                <img
                  src={item.logo}
                  alt={item.name}
                  className="object-contain h-full w-full"
                />
              </div>
              <div>
                <div
                  className={`mx-auto w-12 rounded-full text-lg font-bold ${
                    item.overallScore >= 82
                      ? "text-success-500 bg-success-100"
                      : item.overallScore >= 75
                      ? "text-warning-500 bg-warning-100"
                      : "text-error-500 bg-error-100"
                  }`}
                >
                  {item.overallScore}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Details */}
      <div className="py-2 lg:py-2 border-t-2 lg:border lg:border-gray-200 lg:rounded-lg">
        {/* Render each row as a vertical block */}
        <div className="flex flex-col lg:flex-row w-full py-6 lg:pt-4">
          <p className="text-sm lg:w-60 px-4 text-center font-semibold pb-4 lg:pb-0">
            Budget Match
          </p>
          <div className="grid grid-col-3 grid-flow-col text-sm w-full text-gray-700">
            {vendors.map((item, index) => (
              <p className="text-center px-2" key={index}>
                {item.budgetMatch}
              </p>
            ))}
          </div>
        </div>
        <div className="flex flex-col lg:flex-row w-full border-t-2 py-6">
          <p className="text-sm lg:w-60 px-4 text-center font-semibold pb-4 lg:pb-0 whitespace-nowrap">
            Source System Support
          </p>
          <div className="grid grid-col-3 grid-flow-col text-sm w-full text-gray-700">
            {vendors.map((item, index) => (
              <p className="text-center px-2" key={index}>
                {item.sourceSystemSupport}
              </p>
            ))}
          </div>
        </div>
        <div className="flex flex-col lg:flex-row w-full border-t-2 py-6">
          <p className="text-sm lg:w-60 px-4 text-center font-semibold pb-4 lg:pb-0">
            Company Size
          </p>
          <div className="grid grid-col-3 grid-flow-col text-sm w-full text-gray-700">
            {vendors.map((item, index) => (
              <p className="text-center px-2" key={index}>
                {item.companySize}
              </p>
            ))}
          </div>
        </div>
        <div className="flex flex-col lg:flex-row w-full border-t-2 py-6">
          <p className="text-sm lg:w-60 px-4 text-center font-semibold pb-4 lg:pb-0">
            Revenue Range
          </p>
          <div className="grid grid-col-3 grid-flow-col text-sm w-full text-gray-700">
            {vendors.map((item, index) => (
              <p className="text-center px-2" key={index}>
                {item.revenueRange}
              </p>
            ))}
          </div>
        </div>
        <div className="flex flex-col lg:flex-row w-full border-t-2 pt-6">
          <p className="text-sm lg:w-60 px-4 text-center font-semibold pb-4 lg:pb-3">
            Industry Focus
          </p>
          <div className="grid grid-col-3 grid-flow-col text-sm w-full text-gray-700">
            {vendors.map((item, index) => (
              <p className="text-center px-2" key={index}>
                {item.functionalAreasMatch}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourMatchReport;
