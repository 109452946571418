import { createAsyncThunk } from "@reduxjs/toolkit";
import ShortListAdviceAPI from "../ShortListAdviceAPI.js";
import {
  updateCustomer,
  updateSelectedCustomer,
  updateSelectedCustomerStatus,
} from "../customers/customersSlice.js";

export const saveQuestionsToDb = createAsyncThunk(
  "questionnaire/saveQuestionsToDb",
  async (data, { getState, rejectWithValue }) => {
    try {
      // Access and serialize the specific part of Redux state
      const categories = getState().questions.categories;
      const serializedCategories = JSON.stringify(categories);

      // Log the serialized categories
      console.log("Serialized Categories:", serializedCategories);

      // Include the serialized categories in the data sent to the server
      const payload = { categories: serializedCategories };

      // Post the payload to the server
      const response = await ShortListAdviceAPI.post(
        "api/questionnaire/saveQuestionsToDb",
        payload
      );

      return response.data;
    } catch (error) {
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const submitQuestionnaire = createAsyncThunk(
  "questionnaire/submitQuestionnaire",
  async (data, { getState, rejectWithValue }) => {
    try {
      // Access the categories from Redux state
      const categories = getState().questionnaire.categories;

      // Navigate to the specific subcategory and question to extract the email from the last category.
      // NOTE: These are hard-coded, and should always remain this way.
      const firstName =
        categories[categories.length - 1].subcategories[0].questions[0]
          .userResponse;
      const lastName =
        categories[categories.length - 1].subcategories[0].questions[1]
          .userResponse;
      const customerEmail =
        categories[categories.length - 1].subcategories[0].questions[2]
          .userResponse;
      const customerPhoneNum =
        categories[categories.length - 1].subcategories[0].questions[3]
          .userResponse;
      const company = categories[0].subcategories[0].questions[0].userResponse;

      // Serialize the categories with the additional customerEmail
      const serializedCategories = JSON.stringify(categories);

      // Log the serialized categories and the extracted customerEmail
      console.log("Serialized Categories:", serializedCategories);
      console.log("Customer Email:", customerEmail);

      // Include the serialized categories in the data sent to the server
      // You might also include customerEmail in the payload if needed
      const payload = {
        categories: serializedCategories,
        customerEmail: customerEmail,
        customerPhoneNum: customerPhoneNum,
        firstName: firstName,
        lastName: lastName,
        company: company,
      };

      // Post the payload to the server
      const response = await ShortListAdviceAPI.post(
        "api/questionnaire/submitQuestionnaire",
        payload
      );

      return response.data;
    } catch (error) {
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const getAdminQuestionnaire = createAsyncThunk(
  "questionnaire/getAdminQuestionnaire",
  async (_, { rejectWithValue }) => {
    try {
      // Make a GET request to the server
      const response = await ShortListAdviceAPI.get("api/questionnaire/admin");

      // Convert 'categories' from a string to a JSON object
      const categories = JSON.parse(response.data.categories);

      // Return the parsed categories
      return categories;
    } catch (error) {
      // Ensure error handling accounts for both server and parsing errors
      const errorMessage = error.response?.data?.message || error.message;
      throw rejectWithValue(errorMessage);
    }
  }
);

export const getGptScoresForQuestionnaire = createAsyncThunk(
  "questionnaire/getGptScoresForQuestionnaire",
  async ({ questionnaireId, force }, { rejectWithValue }) => {
    try {
      // Make a GET request to the server
      const response = await ShortListAdviceAPI.post(
        "api/questionnaire/scoreVendors",
        { questionnaireId: questionnaireId, force: force }
      );

      // Return the parsed categories
      return response.data;
    } catch (error) {
      // Ensure error handling accounts for both server and parsing errors
      const errorMessage = error.response?.data?.message || error.message;
      throw rejectWithValue(errorMessage);
    }
  }
);

export const adminSendResultsReady = createAsyncThunk(
  "questionnaire/sendResultsReady",
  async ({ customerEmail, cid }, { dispatch, rejectWithValue, getState }) => {
    // Combine matchReportSelectedVendors and matchReportContactInfo into formData
    const matchReportScores = getState().questionnaire.matchReportScores;
    const questionnaireId = matchReportScores.questionnaireId;

    try {
      // Make a POST request to the server
      const response = await ShortListAdviceAPI.post(
        "/api/email/sendResultsReady",
        { customerEmail, cid, matchReportScores, questionnaireId }
      );

      // Extract the customer object from the response
      const { customer } = response.data;

      if (customer) {
        // Dispatch actions to update customer information in customers slice
        dispatch(updateCustomer(customer)); // This updates the customer in the allCustomers array
        dispatch(updateSelectedCustomerStatus("resultsSent")); // This updates the selectedCustomer in our client instance
      }

      // Return the updated customer object for potential further use
      return customer;
    } catch (error) {
      // Handle errors properly
      const errorMessage = error.response?.data?.message || error.message;
      throw rejectWithValue(errorMessage);
    }
  }
);

export const getQuestionnaire = createAsyncThunk(
  "questionnaire/getQuestionnaire",
  async (_, { rejectWithValue }) => {
    try {
      // Make a GET request to the server
      const response = await ShortListAdviceAPI.get(
        "api/questionnaire/getQuestionnaire"
      );

      // Convert 'categories' from a string to a JSON object
      const categories = JSON.parse(response.data.categories);

      // Return the parsed categories
      return categories;
    } catch (error) {
      // Ensure error handling accounts for both server and parsing errors
      const errorMessage = error.response?.data?.message || error.message;
      throw rejectWithValue(errorMessage);
    }
  }
);

export const setMatchReportSelectedImages = createAsyncThunk(
  "questionnaire/setMatchReportSelectedImages",
  async (vendorNames, { getState }) => {
    const { vendors } = getState(); // Access the entire Redux state
    let result = [];

    vendorNames.forEach((vendorName) => {
      const vendor = vendors.vendors.find((v) => v.VendorName === vendorName);
      if (vendor) {
        result.push(vendor.Logo);
      }
    });

    if (result.length > 3) {
      result = result.slice(0, 3);
    } else {
      while (result.length < 3) {
        result.push("placeholder-url"); // Replace with an actual placeholder URL or leave empty
      }
    }

    return result; // This will be the payload for the fulfilled action
  }
);

// Redux Thunk for submitting a quick questionnaire
export const submitQuickQuestionnaire = createAsyncThunk(
  "questionnaire/submitQuickQuestionnaire",
  async ({ formData }, { rejectWithValue }) => {
    try {
      // Make a POST request to the server
      const response = await ShortListAdviceAPI.post(
        "api/questionnaire/quick",
        formData
      );
      console.log(response);

      console.log(response.data);
      // Convert 'categories' from a string to a JSON object
      // const vendors = JSON.parse(response.data.vendors);

      // Return the parsed vendors
      return response.data.vendors;
    } catch (error) {
      // Ensure error handling accounts for both server and parsing errors
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

// Redux Thunk for sending page updates
export const sendPageUpdate = createAsyncThunk(
  "analytics/sendPageUpdate",
  async ({ page }, { rejectWithValue }) => {
    try {
      // Send the page view update to the backend
      const response = await ShortListAdviceAPI.post(
        "/api/questionnaire/sendQQAnalytics",
        {
          page,
        }
      );
      return response.data;
    } catch (error) {
      // Handle errors gracefully
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

// Redux Thunk to fetch analytics data
export const getAnalytics = createAsyncThunk(
  "analytics/getAnalytics",
  async ({ startDate, endDate, uniqueIPs }, { rejectWithValue }) => {
    try {
      // Make a GET request to the server
      const response = await ShortListAdviceAPI.get(
        "/api/questionnaire/analytics",
        {
          params: { startDate, endDate, uniqueIPs },
        }
      );

      // Return the fetched data
      return response.data.data;
    } catch (error) {
      // Handle errors and return a meaningful error message
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

// Redux Thunk to fetch analytics data
export const sendQQResultsEmail = createAsyncThunk(
  "questionnaire/sendQQResultsEmail",
  async ({ email, url }, { rejectWithValue }) => {
    try {
      // Make a GET request to the server
      const response = await ShortListAdviceAPI.post(
        "/api/email/email-qq-results",
        { email, url }
      );

      // Return the fetched data
      return response.data;
    } catch (error) {
      // Handle errors and return a meaningful error message
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

// Redux Thunk to fetch analytics data
export const subscribeToMailchimp = createAsyncThunk(
  "questionnaire/subscribeToMailchimp",
  async ({ email }, { rejectWithValue }) => {
    try {
      // Make a GET request to the server
      const response = await ShortListAdviceAPI.post("/api/email/subscribe", {
        email,
      });

      // Return the fetched data
      return response.data;
    } catch (error) {
      // Handle errors and return a meaningful error message
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);
