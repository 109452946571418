import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { updateQuestionType } from '../../../../../redux/questions/questionsSlice'; // Adjust the import path
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const QuestionTypeDropdown = ({ selectedValue, questionId }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState('');
  const questionTypes = [
    'Dropdown',
    'Checklist',
    'Radio Button',
    'One Line Text Entry',
    'Large Text Field',
    'Source Systems'
  ];

  useEffect(() => {
    if (questionTypes.includes(selectedValue)) {
      setSelected(selectedValue);
    } else {
      setSelected('');
    }
  }, [selectedValue]);

  const handleChange = (newType) => {
    setSelected(newType);
    dispatch(updateQuestionType({ id: questionId, type: newType }));
  };

  return (
    <Menu as="div" className="relative inline-block text-left w-72 text-sm font-semibold">
      <div>
        <Menu.Button className="inline-flex w-full justify-between items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-left font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {selected || "Answer Type"}
          <ChevronDownIcon className="h-4 w-4 text-gray-900" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute mt-1 left-0 z-10 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-300 ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {questionTypes.map((type) => (
              <Menu.Item key={type}>
                {({ active }) => (
                  <button
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-4 py-2 text-left text-sm'
                    )}
                    onClick={() => handleChange(type)}
                  >
                    {type}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default QuestionTypeDropdown;
