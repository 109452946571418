import React, { useEffect, useState } from "react";
import QuickHeader from "./components/QuickHeader.js";
import { useDispatch, useSelector } from "react-redux";
import BookFreeConsult from "./components/BookFreeConsult";
import YourMatchReport from "./components/YourMatchReport";
import ResultsHeader from "./components/ResultsHeader";
import WebflowFooter from "../../../components/WebflowFooter.js";
import { generateMagicLink } from "./functions/generateMagicLink.js";
import { sendPageUpdate } from "../../../redux/questionnaire/questionnaireThunk.js";
import { setMagicLink } from "../../../redux/questionnaire/questionnaireSlice.js";

export default function QuickResults() {
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.questionnaire.quickQuestionnaireResults
  );

  const [vendors, setVendors] = useState([]);

  const [currentMagicLink, setCurrentMagicLink] = useState([]);
  useEffect(() => {
    // Function to decode magic link from URL
    const decodeMagicLink = (magicLink) => {
      try {
        const jsonString = decodeURIComponent(escape(atob(magicLink)));
        return JSON.parse(jsonString);
      } catch (error) {
        console.error("Failed to decode magic link:", error);
        return null;
      }
    };

    // Extract and parse magic link data from URL if present
    const urlParams = new URLSearchParams(window.location.search);
    const magicLinkData = urlParams.get("mld");

    if (magicLinkData) {
      const parsedVendors = decodeMagicLink(magicLinkData);
      setVendors(parsedVendors); // Set the parsed vendors data
      console.log("Extracted Vendors from Magic Link:", parsedVendors); // Print the extracted data
      setCurrentMagicLink(window.location.href);
    }

    if (process.env.REACT_APP_ENV === "development") {
      console.log("not sending analytics in dev env.");
    } else {
      dispatch(sendPageUpdate({ page: "/quick-results" }));
    }
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      const magicLink = generateMagicLink(data);
      console.log("Generated Magic Link:");
      console.log(magicLink);
      setCurrentMagicLink(magicLink);
      setVendors(data);
    }
  }, [data]);

  useEffect(() => {
    console.log("currentMagicLink");
    console.log(currentMagicLink);
    dispatch(setMagicLink(currentMagicLink));
  }, [currentMagicLink]);

  return (
    <div>
      <div className="isolate overflow-x-hidden bg-white px-6 py-8 lg:px-8">
        <QuickHeader />
      </div>
      <div>
        <ResultsHeader magicLink={currentMagicLink} />
      </div>
      <div className="px-2 lg:px-8">
        <div className="py-4">
          <div className="flex flex-col w-full md:flex-row gap-4">
            {/* BookFreeConsult Component */}
            <BookFreeConsult />

            {/* YourMatchReport Component */}
            <YourMatchReport vendors={vendors} />
          </div>
        </div>
      </div>
      <WebflowFooter />
    </div>
  );
}
