import React, { useState } from "react";
import SaveResultsForm from "./SaveResultsForm";
import { ShareIcon } from "@heroicons/react/outline";

export default function ResultsHeader({ magicLink }) {
  const [copySuccess, setCopySuccess] = useState(""); // State for feedback message

  const handleCopyLinkClick = async () => {
    try {
      await navigator.clipboard.writeText(magicLink); // Copy to clipboard
      setCopySuccess("Link copied!"); // Update success message
      setTimeout(() => setCopySuccess(""), 3000); // Clear message after 3 seconds
    } catch (error) {
      setCopySuccess("Failed to copy link."); // Handle errors
      setTimeout(() => setCopySuccess(""), 3000);
    }
  };

  return (
    <div>
      <div className="bg-gray-100 max-w-full py-10 text-center">
        <h1 className="mx-auto max-w-2xl mb-4 text-center font-bold text-2xl lg:text-3xl">
          Instamatch Results
        </h1>
        <p className="text-gray-500 px-20">
          Send results to your inbox or share with a friend!
        </p>
        <div className="w-full">
          <SaveResultsForm />
        </div>
        <div className="flex items-center justify-center p-4">
          <div
            onClick={handleCopyLinkClick}
            className="hover:shadow-md text-sm cursor-pointer flex items-center justify-center space-x-2 p-2 px-4 bg-white rounded-full border"
          >
            <ShareIcon className="h-5 w-5" />
            <span>Share a link to this page</span>
          </div>
        </div>
        {/* Display copy success message */}
        {copySuccess && (
          <p className="text-green-600 text-sm mt-2">{copySuccess}</p>
        )}
      </div>
    </div>
  );
}
