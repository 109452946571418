import React from "react";
import Logo from "../../../../assets/logo.png";

const BookFreeConsult = () => {
  const openLink = () => {
    const url =
      "https://shortlistmatch.pipedrive.com/scheduler/bE7a5OuZ/30-minute-meeting";
    window.open(url, "_blank"); // Opens the link in a new tab
  };

  return (
    <div className="lg:max-w-sm w-full bg-white rounded-lg p-6 border border-gray-200">
      {/* Header */}
      <div className="flex items-center mb-4">
        <img className="h-10 lg:h-12" src={Logo} alt="ShortlistAdvice Logo" />
      </div>

      {/* Content */}
      <p className="text-xs lg:text-sm text-gray-400 mb-4">
        These scores are based on very basic criteria. More should be considered
        such as user volumes, dimensions, non-financial source systems,
        versioning, forecasting methodologies, availability of pre-built
        content, implementation timelines + cost, and more.
      </p>
      <p className="text-xs lg:text-sm text-gray-400 mb-6">
        For a full understanding of your options, connect with us today. Use our
        20+ years of experience in CPM to your advantage.
      </p>

      {/* Checklist */}
      <div className="space-y-4 mb-6 pt-8 border-t-2 border-black">
        <div className="flex items-center">
          <span className="text-blue-600 text-lg mr-2">✔</span>
          <p className="text-sm text-gray-800 font-semibold">
            Get understanding of CPM vendor landscape
          </p>
        </div>
        <div className="flex items-center">
          <span className="text-blue-600 text-lg mr-2">✔</span>
          <p className="text-sm text-gray-800 font-semibold">
            Should you stick with Excel?
          </p>
        </div>
        <div className="flex items-center pb-8">
          <span className="text-blue-600 text-lg mr-2">✔</span>
          <p className="text-sm text-gray-800 font-semibold">
            What are all the options for my use case?
          </p>
        </div>
      </div>

      {/* Call-to-Action */}
      <button
        onClick={openLink}
        className="w-full bg-blue-600 text-white font-semibold py-3 rounded-lg hover:bg-blue-700 transition duration-300"
      >
        Book Free 15-min Consult →
      </button>
    </div>
  );
};

export default BookFreeConsult;
