import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setQuestionUserResponse } from "../../../../../redux/questionnaire/questionnaireSlice";
import RatingInput from "../inputs/RatingInput/RatingInputContainer";
import Checklist from "../inputs/CheckList";
import Dropdown from "../../../../../components/Dropdown";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import SourceSystemsInput from "../SourceSystemsInput/SourceSystemsInput";

const QuestionDisplay = ({ question }) => {
  const dispatch = useDispatch();
  const [showTooltip, setShowTooltip] = useState(false);

  const [userResponse, setUserResponse] = useState()

  const handleUserResponse = (value) => {
    setUserResponse(value);
    dispatch(
      setQuestionUserResponse({ questionId: question.id, userResponse: value })
    );
  };

  useEffect(() => {
    if (question.type === "One Line Text Entry" || question.type === "Large Text Field") {
      setUserResponse(question.userResponse)
    }
  }, [question.type])
  

  // Switch statement to render based on question type
  switch (question.type) {
    case "Source Systems":
      return (
        <div className="mb-6">
          <div className="flex justify-between">
            <label className="block text-sm text-md font-semibold text-grayBlue-700 mb-3 pr-2">
              {question.text}
            </label>
            {question.tooltip && question.tooltip !== "" ? (
              <div className="">
                <div
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                  className="cursor-pointer bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-300 p-1 rounded-lg relative mb-1"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5" />
                  {showTooltip && (
                    <div className="absolute top-2 right-2 w-96 p-2 bg-white shadow-lg border border-gray-300 text-xs z-50">
                      {question.tooltip}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <SourceSystemsInput
            onSelectOption={(value) => handleUserResponse(value)}
            userResponse={question.userResponse}
          />
          {question.showImportance && (
            <div className="w-full max-w-xs pb-4">
              <RatingInput
                questionId={question.id}
                currentValue={question.userImportanceRating}
              />
            </div>
          )}
        </div>
      );
    case "Dropdown":
      return (
        <div className="mb-6">
          <div className="flex justify-between">
            <label className="block text-sm text-md font-semibold text-grayBlue-700 mb-3 pr-2">
              {question.text}
            </label>
            {question.tooltip && question.tooltip !== "" ? (
              <div className="">
                <div
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                  className="cursor-pointer bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-300 p-1 rounded-lg relative mb-1"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5" />
                  {showTooltip && (
                    <div className="absolute top-2 right-2 w-96 p-2 bg-white shadow-lg border border-gray-300 text-xs z-50">
                      {question.tooltip}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <Dropdown
            onSelectOption={(value) => handleUserResponse(value)}
            selectedValue={question.userResponse || ""}
            options={question.answers.map((answer) => answer.text)}
            maxHeight="max-h-48"
          />
          {question.showImportance && (
            <div className="w-full max-w-xs pb-4">
              <RatingInput
                questionId={question.id}
                currentValue={question.userImportanceRating}
              />
            </div>
          )}
        </div>
      );
    case "Checklist":
      return (
        <div className="mb-6">
          <div className="flex justify-between">
            <label className="block text-sm text-md font-semibold text-grayBlue-700 mb-3 pr-2">
              {question.text}
            </label>
            {question.tooltip && question.tooltip !== "" && (
              <div className="pb-2">
                <div
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                  className="cursor-pointer bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-300 p-1 rounded-lg relative mb-1"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5" />
                  {showTooltip && (
                    <div className="absolute top-2 right-2 w-96 p-2 bg-white shadow-lg border border-gray-300 text-xs z-50">
                      {question.tooltip}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <Checklist
            question={question}
            currentlySelected={question.userResponse || []}
            onUpdateUserResponse={(value) => handleUserResponse(value)}
          />

          {question.showImportance && (
            <div className="w-full max-w-xs pb-4">
              <RatingInput
                questionId={question.id}
                currentValue={question.userImportanceRating}
              />
            </div>
          )}
        </div>
      );
    case "Radio Button":
      return (
        <div className="mb-6">
          <div className="flex justify-between">
            <label className="block text-sm text-md font-semibold text-grayBlue-700 mb-3 pr-2">
              {question.text}
            </label>
            {question.tooltip && question.tooltip !== "" && (
              <div className="pb-2">
                <div
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                  className="cursor-pointer bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-300 p-1 rounded-lg relative mb-1"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5" />
                  {showTooltip && (
                    <div className="absolute top-2 right-2 w-96 p-2 bg-white shadow-lg border border-gray-300 text-xs z-50">
                      {question.tooltip}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          {question.answers.map((answer, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="radio"
                id={answer.id}
                name={question.id}
                value={answer.text}
                onChange={(e) => handleUserResponse(e.target.value)}
                checked={question.userResponse === answer.text} // Check if this answer is the selected one
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
              />
              <label htmlFor={answer.id} className="ml-2 text-sm text-gray-700">
                {answer.text}
              </label>
            </div>
          ))}
          {question.showImportance && (
            <div className="w-full max-w-xs pb-4">
              <RatingInput
                questionId={question.id}
                currentValue={question.userImportanceRating}
              />
            </div>
          )}
        </div>
      );
    case "One Line Text Entry":
      return (
        <div className="mb-6">
          <div className="flex justify-between">
            <label className="block text-sm text-md font-semibold text-grayBlue-700 mb-3 pr-2">
              {question.text}
            </label>
            {question.tooltip && question.tooltip !== "" && (
              <div className="pb-2">
                <div
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                  className="cursor-pointer bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-300 p-1 rounded-lg relative mb-1"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5" />
                  {showTooltip && (
                    <div className="absolute top-0 -l-48 w-96 h-12 overflow-y-auto bg-gray-100 text-xs">
                      {question.tooltip}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <input
            onChange={(e) => handleUserResponse(e.target.value)}
            type="text"
            value={userResponse}
            className="border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600 w-full"
          />
          {question.showImportance && (
            <div className="w-full max-w-xs pb-4">
              <RatingInput
                questionId={question.id}
                currentValue={question.userImportanceRating}
              />
            </div>
          )}
        </div>
      );
    case "Large Text Field":
      return (
        <div className="mb-6">
          <div className="flex justify-between">
            <label className="block text-sm text-md font-semibold text-grayBlue-700 mb-3 pr-2">
              {question.text}
            </label>
            {question.tooltip && question.tooltip !== "" && (
              <div className="pb-2">
                <div
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                  className="cursor-pointer bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-300 p-1 rounded-lg relative mb-1"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5" />
                  {showTooltip && (
                    <div className="absolute top-2 right-2 w-96 p-2 bg-white shadow-lg border border-gray-300 text-xs z-50">
                      {question.tooltip}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <textarea
            value={userResponse}
            onChange={(e) => handleUserResponse(e.target.value)}
            className="border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600 w-full"
            rows="4"
          ></textarea>
          {question.showImportance && (
            <div className="w-full max-w-xs">
              <RatingInput
                questionId={question.id}
                currentValue={question.userImportanceRating}
              />
            </div>
          )}
        </div>
      );
    default:
      return null;
  }
};

export default QuestionDisplay;
