import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import EmptyTable from "./EmptyTable";
import { TrashIcon, PencilIcon } from "@heroicons/react/outline";
import { setSelectedRequirement, toggleEditor } from "../../../../../redux/requirements/requirementsSlice";
import { deleteRequirement } from "../../../../../redux/requirements/requirementsThunk";
import Pagination from "./Pagination";

const RequirementsTable = () => {
  const requirements = useSelector(
    (state) => state.requirements.allRequirements
  );
  const dispatch = useDispatch();

  const handleAddCategoryClick = () => {
    // dispatch(setSelectedCategory(null)); // Reset selected requirement when adding new
    // dispatch(toggleSlideout());
  };

  const handleEditClick = (requirement) => {
    dispatch(setSelectedRequirement(requirement));
    dispatch(toggleEditor());
  };

  const handleDeleteClick = (requirementId, requirementTitle) => {
    const userInput = window.prompt(
      `Please type '${requirementTitle}' to confirm deletion:`
    );

    if (userInput === requirementTitle) {
      dispatch(deleteRequirement(requirementId));
    } else {
      // Do nothing
    }
  };

  return (
    <div className="p-4">
      <div className="flex w-full min-h-full items-center justify-start flex-col text-center font-sans text-sm shadow-sm text-left">
        <div className="overflow-x-auto min-h-full min-h-full min-w-full ">
          {requirements.length === 0 ? (
            <EmptyTable handleAddCategoryClick={handleAddCategoryClick} />
          ) : (
            <table className="min-w-full bg-white">
              <thead className="bg-gray-200 w-full border-separate text-gray-500">
                <tr>
                  <th className="text-left py-3 px-4 text-start rounded-tl-lg border-grayBlue-200 font-semibold">
                    Category Name
                  </th>
                  <th className="text-left py-3 px-4 text-start border-l border-grayBlue-200 font-semibold">
                    Functional Area
                  </th>
                  <th className="text-left py-3 px-4 text-start border-l border-grayBlue-200 font-semibold">
                    Requirement
                  </th>
                  <th className="text-left py-3 px-4 text-start rounded-tr-lg border-l border-grayBlue-200 font-semibold">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {requirements.map((requirement, index) => (
                  <tr
                    key={index}
                    className={
                      requirement.title !== "Company Details" &&
                      requirement.title !== "Contact"
                        ? "border-b"
                        : "border-b bg-gray-50 text-gray-400 italic text-sm"
                    }
                  >
                    <td className="py-3 px-4 text-left">
                      {requirement.category}
                    </td>
                    <td className="py-3 px-4 text-left">
                      {requirement.functionalArea}
                    </td>
                    <td className="py-3 px-4 text-left">{requirement.name}</td>
                    <td className="py-3 px-4 text-left flex gap-1">
                      <button
                        className="bg-gray-200 hover:bg-gray-300 py-1 px-2 rounded-md"
                        onClick={() => handleEditClick(requirement)}
                      >
                        <PencilIcon className="h-5 w-5" />
                      </button>
                      {requirement.title !== "Company Details" &&
                        requirement.title !== "Contact" && (
                          <button
                          className="bg-gray-200 hover:bg-gray-300 py-1 px-2 rounded-md"
                            onClick={() =>
                              handleDeleteClick(
                                requirement._id,
                                requirement.name
                              )
                            }
                          >
                            <TrashIcon className="h-5 w-5" />
                          </button>
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Pagination />
    </div>
  );
};

export default RequirementsTable;
